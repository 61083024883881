.my-container-2{
    position: fixed;
    z-index:111114;
    top: 0;
    height:100vh !important;
    background: linear-gradient(to right, #e6dada, #dfedf0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.my-main img{
    width: 200px;
    height:200px;
    margin: auto;
}
.piaic-logo{
    display: block;
}
.my-text{
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #017969;
    margin-bottom: 50px !important;
  
}
.Rectangle-109 {
    padding: 20px 60px;
    color: white !important;
    cursor: pointer;
    border-radius: 100px;
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  }
  .Rectangle-109:hover{
      color: white;
  }
  .slide{
    transform: translateY(-100vh);
    transition: all ease-in 1s;
  }
 