.PresidentSaysVideo-bg{
  background-color: #2b2929;
  /* height: 500px; */
  padding: 0 4% 50px 4%;
}

.PresidentSays-heading{
  font-size: 42px;
  text-align: left;
  color: #ffffff;
  font-family: myfontBold;
  padding-bottom: 20px;
  padding-top: 50px;
}

.PresidentSays-video-list{
  /* min-width: 400px; */
  padding-top: 20px;    
  padding-bottom: 20px;
  max-height: 480px;
  height: 480px;
  background-color: #484848;
  overflow-y: scroll;
}
.PresidentSays-video-list::-webkit-scrollbar{
  width: 10px;
   background-color: #414141;
  /* background-color: #05d88c; */
}
.PresidentSays-video-list::-webkit-scrollbar-thumb{

  background-color: #05d88c;
}

.PresidentSays-video-list-image{
  display: inline-block;
  width: 50%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.PresidentSays-video-list-content{
  display: inline-block;
  width: 50%;
  padding-left: 1.5%;
}
.PresidentSays-video-list ul{
  padding-left: 2%;
  padding-right: 2%;
}
.PresidentSays-video-list ul li{
  cursor: pointer;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.PresidentSaysVideo-video-list-flexbox{
  width: 100%;
  display: flex;
  height: 100px;
}





.PresidentSays-video-list-content h4{
  font-family: myfontRegular;
    font-size: 13px;
    text-align: left;
    color: #9becd7;
    margin: 0 0 4px 0;
    display: -webkit-box;
    max-height: 32px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
}
.PresidentSays-video-list-content p{
  max-height: 32px;
  overflow: hidden;
  font-family: myfontLight;
  font-size: 10px;
  /* line-height: 1.36; */
  /* letter-spacing: -0.3px; */
  text-align: left;
  color: #ffffff;
}

.PresidentLaunchingWeb-content-video-h2{
      /* width: 217px; */
    /* height: 16.5px; */
    font-family: myfontRegular;
    font-size: 20px;
    /* font-weight: bold; */
    /* font-style: normal; */
    /* font-stretch: normal; */
    /* line-height: 1.38; */
    /* letter-spacing: -0.6px; */
    text-align: left;
    color: #9becd7;
}
.PresidentLaunchingWeb-content-video-p{
  max-width: 500px;
  max-height: 35px;
  text-align: justify;
  float: left;
  font-family: myfontLight;
  font-size: 12px;
  color: #ffffff;
  margin: 0 0 4px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
}

.PresidentLaunchingWeb-video-col, .PresidentLaunchingWeb-video-desc-col, .PresidentLaunchingWeb-video-list-col{
  padding-left: 5px;
  padding-right: 5px;
}

/* @media (max-width: 1221px){
  .PresidentLaunchingWeb-video-list-col{
      max-width: 400px;
  }
} */
@media (max-width: 991px){
  .PresidentSays-video-list-image{
      max-width: 20%;
      min-width: 100px;
  }
  .PresidentSaysVideo-video-list-flexbox{
    height: 10vw;
    min-height: 55px;
  }

  .PresidentLaunchingWeb-video-iframe{
    height: 50vw;
  }
  .PresidentSays-video-list{
    height: auto;
  }
}
.PresidentSaysVideo-video-list-onVideo{
  color: #04c492 !important;
    text-decoration: underline;
}