@import url(https://fonts.googleapis.com/css?family=Montserrat);
::-moz-selection {
  color: white; 
  background: #51d98d;
}
::selection {
  color: white; 
  background: #51d98d;
}
html,body{
  /* height:100%;
  width:100%; */
  overflow-x:hidden; 
}
body {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden !important; */
  min-width: 350px;

  
  /* padding-top: 60px; */
}
/* @font-face {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"),local("OpenSans-Semibold"),url('https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSqRDOzjiPcYnFooOUGCOsRk.woff') format("woff")
} */
@font-face{
  font-family: myfontBold;
  src: url(/static/media/OpenSans-Bold.50145685.ttf);
}
@font-face{
  font-family: myfontExtraBold;
  src: url(/static/media/OpenSans-ExtraBold.8bac22ed.ttf);
}
@font-face{
  font-family: myfontItalic;
  src: url(/static/media/OpenSans-Italic.c7dcce08.ttf);
}
@font-face{
  font-family: myfontLight;
  src: url(/static/media/OpenSans-Light.1bf71be1.ttf);
}
@font-face{
  font-family: myfontRegular;
  src: url(/static/media/OpenSans-Regular.629a55a7.ttf);
}
*{
  font-family: "myfontRegular",sans-serif;
  
}


/* Loader */
.LoaderContainer{
  width:100%;
  height:100vh;
  position: fixed;
  top:0;
  z-index:1111111;
  background: rgba(255, 241, 241, 0.534)
}
.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%)
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #017969;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #017969 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.swal2-title{
  color: rgba(255, 255, 255, 0.842) !important;
}
.swal2-content{
  color: rgba(255, 255, 255, 0.842) !important;
}
.swal2-icon.swal2-warning.swal2-animate-warning-icon{
  color: rgba(255, 255, 255, 0.842) !important;
}
.swal2-container{
  background: transparent !important;
}

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)>.swal2-modal{
  background: rgba(0, 0, 0, 0.78);

}
#swal2-content{
  font-size: 15px !important;
}
#alert-div{
  color: rgba(255, 255, 255, 0.842) !important;
}
#alert-div a{
  color: rgb(93, 163, 255);
}






.my-top-alert{
  margin-top:60px !important;
  cursor: pointer !important;
  background: rgba(0, 0, 0, 0.26) !important;
  box-shadow: 0px 0px 10px inset rgba(0,0,0, 0.4);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

}
.my-top-alert:hover{
  background: rgba(0, 0, 0, 0.479) !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

}

.my-top-alert a {
  color: white !important;
}

.my-form-alert{
  margin-top:60px !important;
  cursor: pointer !important;
  max-width: 600px !important;
  /* background: rgba(0, 0, 0, 0.26) !important; */
  background: -webkit-linear-gradient(right, rgba(8, 216, 141,1), rgba(14, 181, 164,0.8)) !important;
  background: linear-gradient(to left, rgba(8, 216, 141,1), rgba(14, 181, 164,0.8)) !important;
  box-shadow: 0px 0px 10px inset rgba(0,0,0, 0.4);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  color: white !important;
  /* font-weight: bold; */
  min-height:380px !important; 

}
.my-form-alert:hover{
  /* background: rgba(0, 0, 0, 0.479) !important; */
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

}
.my-form-alert .iziToast-message{
  font-size: 20px !important;
  line-height: 25px !important;
  text-align: left !important;
  position: relative;
  top: 50px !important;
}
.my-form-alert .iziToast-title{
  margin:10px auto !important;
  font-size: 30px !important;
  display: block !important;
  line-height: 25px !important;
  text-align: center !important;
  /* border: 1px solid red; */
  width: 100% !important;
}
.my-form-alert .iziToast-close{
  height: 50px !important;
}
.my-btn-div{
  position: relative;
  top:-50px !important;
}
.rdt{
  font-weight: bold;
}

.rdt-red {
  font-weight: bold;
  color: rgb(165, 0, 0);
}

.date-check-p{
  max-width:555px;
  margin: auto;
  font-size: 14px;
  text-align: justify;
  word-break: break-all;
}
@media (max-width:768px){
  .my-btn-div{
    top: 0px !important;
  }
  .date-check-p{
    max-width:70vw;
  }
}

@media (max-width:450px){
  .my-form-alert .iziToast-message{
    font-size:15px !important; 
    top: 10px !important;
  }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body{
  padding-top: 55px;
}

@media print {
  body {-webkit-print-color-adjust: exact;}
  #printButton{
    display: none;
  }


  #pdfText{
    margin-top: 10%;
    font-size: 22px;
  }

  .copyright{
    position: fixed;
    bottom: 0;
  }
  }
/* .idCardCont{
  max-width: 1000px !important;
} */



/* width height classes */
.height-10{
  height: 10%;
}
.height-20{
  height: 20%;
}
.height-30{
  height: 30%;
}
.height-40{
  height: 40%;
}
.height-50{
  height: 50%;
}
.height-60{
  height: 60%;
}
.height-70{
  height: 70%;
}
.height-80{
  height: 80%;
}
.height-90{
  height: 90%;
}


.hw{
  width: 100%;
}
.hh{
  height: 100%;
}


.width-10{
  width: 10%;
}
.width-20{
  width: 20%;
}
.width-30{
  width: 30%;
}
.width-40{
  width: 40%;
}
.width-50{
  width: 50%;
}
.width-60{
  width: 60%;
}
.width-70{
  width: 70%;
}
.width-80{
  width: 80%;
}
.width-90{
  width: 90%;
}


.slick-dots li.slick-active button:before{
  opacity: 1 !important;
}
.slick-dots li button:before{
  opacity: .4 !important;
}

/* height width end */
.App {
  text-align: center;
}
.home-bg{
  /* background-color: #efefef; */
}
button:hover,.myDiv h1:hover{
  cursor: pointer;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#alert-div{
  padding: 10px 20px;
  text-align: left;
}
#alert-div h4{
  font-weight: bolder;
}


.App-link {
  color: #61dafb;
}
.groupImageDiv{
  background-color: #f9f9f9;
}
.groupImage{
  vertical-align: middle;
  border-style: none;
  margin-top: -5%;
  border-radius: 10px;
  width: 75%;
  max-width: 800px;
  box-shadow: 0px 0px 60px -20px black;
}
*{
  -webkit-transition: 0.3s;
  transition: 0.3s;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.sliderImg{
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: rgba(228, 228, 228, 0.24);
}
.slider-doc{
display: flex;
padding-bottom:100px;
padding-top: 50px;
}
.slider-doc-f1{
  flex: 1 1;
}
.slider-doc-f2{
  flex: 1 1;
}
.video-cont{
    height: 100%;
}

.container{
  max-width: 1600px !important;
}

.slider-doc-txt{
  width: 90%;
}

.video-cont h1{
  font-family: myfontRegular;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.51;
  letter-spacing: normal;
  color: rgb(4, 86, 84);
}
.slider-doc-txt h1{
  font-family: myfontRegular;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.51;
  letter-spacing: normal;
  text-align: left;
  color: rgb(4, 86, 84);
}
.slider-doc-txt p{
  text-align: justify !important;
  font-family: myfontRegular;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: rgb(134, 132, 163);

}

.box1{
  width: 100%;
  min-height: 545px;

  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-image: -webkit-linear-gradient(left, rgb(230, 218, 218), rgb(223, 237, 240));
  background-image: linear-gradient(to right, rgb(230, 218, 218), rgb(223, 237, 240));
    padding-bottom: 50px;
}
.box1-cont{
  width: 85% !important;
  max-width: 1400px;
  position: relative;
  z-index: 5;
}
.box1-cont h1{
  font-family: myfontRegular;
  font-size: 39px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(4, 86, 84);
  padding-left: 40px;
}

.box1-cont-flexbox{
  display: flex;
  
}
.box1-cont-flex{
  flex: 1 1;
  padding-bottom: 50px;
}
.box1-cont-flex-box:hover{
  cursor: pointer;
}
.box1-cont-flex-box{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width:  398px;
  width: 100%;
  height: 217px;
  border-radius: 5px;

}
.news-btn1{
width: 100px;
align-self: center;
}
.news-btn1 img{
  height: 60px;
  -webkit-transform: scale(-1);
          transform: scale(-1);
}
.news-btn2 img{
  height: 60px;
}
.news-btn2{
  width: 100px;
  align-self: center;

}
.news-box{
  width: 100%;
  height: 1015.9px;
  background-image: -webkit-linear-gradient(201deg, rgb(1, 121, 105), rgb(9, 52, 54));
  background-image: linear-gradient(249deg, rgb(1, 121, 105), rgb(9, 52, 54));
}

.news-cont-head h1{
  font-family: myfontRegular;
  font-size: 39px;
  font-weight: bold;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  padding-top: 67px;
  padding-bottom: 77px;
  color: rgb(0, 206, 159);
  padding-left: 140px;
}
.news{
  width: 100%;
    background-image: -webkit-linear-gradient(#f8f9fa, #e9ecef);
    background-image: linear-gradient(#f8f9fa, #e9ecef);
    box-shadow: 0px 0px 35px -9px black;
    padding-bottom: 50px;
}
.news-cont{
  max-width: 1600px;
  width: 90%;
  position: relative;
  z-index: 5;
}

.news-cont-flexbox{
  display: flex;
}
.news-cont-flex{
  flex: 1 1;
}
.news-cont-flex-box h1{
  width: 100%;
  font-family: myfontRegular;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  float: left;
  color: rgb(4, 86, 84);
  padding: 30px 30px 10px 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.video{
  width: 560px;
  height: 315px;
}
.news-cont-flex-box button{
  max-width:  128.3px;
  height: 42.5px;
  border-radius: 320px;
  border: solid 1px rgb(0, 206, 159);
  background-color: rgb(255, 255, 255);
  font-family: myfontRegular;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgb(0, 206, 159);
}
.news-cont-flex-box p{
  max-width: 90%;
  height: 205px;
  font-family: myfontRegular;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: rgb(0, 0, 0);
  overflow: hidden;
  padding-left: 5px;
  margin-bottom: 30px;
}
.news-cont-flex-box{
  overflow: hidden;
  max-width: 398px;
  width: 85%;
  padding-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgb(255, 255, 255);
  
}

.news-cont-flex-box img{
width: 100%;

}

.boxTxt{
  margin: 0;
  font-family: myfontRegular;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

.footBox{
  max-width: 1350px !important;
  
}
.footBox h1 img {
  height: 70px;
}
.footBox h1  {
  text-align: left;
}
.myDiv{
  width: 80%;
  margin: auto;
  
}

.foot-f1 p{
  max-width: 130px;
  height: 32px;
  font-family: myfontRegular;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}
.foot-f1 ul{
  list-style-type: none;
  padding-left: 0px;
}
.foot-f1 li{
  font-family: myfontRegular;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.foot-f2 p{
  max-width: 130px;
  height: 32px;
  font-family: myfontRegular;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}
.foot-f2 ul{
  list-style-type: none;
  padding-left: 0px;
}
.foot-f2 li{
  height: 35px;
  font-family: myfontRegular;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.foot-f3 p{
  max-width: 264px;
  height: 32px;
  font-family: myfontRegular;
  font-size: 26px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.foot-pics{
  margin-left: 15px;
  max-width: 81px;
  height: 42px;
  font-family: myfontRegular;
  font-size: 34px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.email-address{
  max-width: 396px;
  width: 50%;
  height: 52px;
  padding-left: 0px;
  border: solid 1px rgb(112, 112, 112);
  background-color: rgb(112, 112, 112);
  outline-style: none;
  float: left;
  border-radius: 5px;
  padding-left: 20px;
  font-family: myfontRegular;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.email-address::-webkit-input-placeholder{
  font-family: myfontRegular;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.email-address::-ms-input-placeholder{
  font-family: myfontRegular;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.email-address::placeholder{
  font-family: myfontRegular;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: rgb(255, 255, 255);
}

.submitBtn{
  margin-left: -90px;
  outline-style: none;
  max-width:  131px;
  width: 20%;
  height: 52px;
  border-radius: 5px;
  border: solid 1px rgb(112, 112, 112);
  background-color: rgb(248, 210, 0);
}
.my-bara-container{
  background-color: #f9f9f9;
}
.connnct-with-us{
  margin-top: 50px;
}

.gapInStP{
  height: 300px;
}

.copyright{
width: 100%;
height: 45px;
  background-color: rgb(85, 84, 84);
  padding-top: 10px;
  align-items: center;
}

.copyright p  {
  width: 100%;
  height: 22px;
  font-family: myfontRegular;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: rgb(214, 214, 214);
  padding: 0px;
  margin-top: 20px;
  position: absolute;
}
.copyright img{
  float: right;
  height: 35px;
  padding-right: 30px;
  position: absolute;
  margin-top: -5px;
  right: 0px;
}
.nav-flex-1 img:hover{
  cursor: pointer;
}
.copyright div{
  
}
@media screen and (max-width: 1050px) {
  .box1-cont h1{
    font-size: 2em;
  }
  .slider-doc-txt h1,.video-cont h1{
    font-size: 30px;
  }
  .news-cont-flex2{
    display: none;
  }
  .news-cont-flex3{
    display: none;
  }
  .slider-doc{
    flex-direction: column;
  }
  .news-cont-head h1{
    padding-left: 0px;
    text-align: center;
  }
}
@media screen and (max-width: 1615px) and (min-width: 901px){
  .news-cont-flex3{
    display: none;
  }
  
}

@media screen and (max-width: 1400px) {
  .news-cont-flex-box p{
    font-size: 1em;
    height: 145px;

  }
  .news-cont-flex-box h1{
    font-size: 1.1em;
  }
  .news-cont-flex-box{
    width: 80%;
  }
  .news-cont-flex-box button{
    padding: 10px;
    height: auto;
  }
  .news-box{
    height: 900px;
  }
  .boxTxt{
    font-size: 1.7vw;
  }

}
@media screen and (max-width: 990px) {
  .boxTxt{
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 767px){
.boxTxt {
    font-size: 1.5em;
}
}

@media screen and (max-width: 1300px) {
  
  .box1-cont h1{
    text-align: center;
    padding-left: 0px;
  }
}
@media screen and (max-width: 1250px) and (min-width: 200px){
  
  .slider-doc{
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) and (min-width: 400px){
   .slider-doc-txt{
    padding-left: 20px;
  }
  .video{
    width: 400px;
    height: 200px;
  }
}
@media screen and (max-width: 450px) and (min-width: 100px){
  .news-btn1 img{
    width: 40px;
  }
  .news-cont-flex-box button{
    max-height: 28px;
    font-size: 10px;
  }
  .news-cont-flex-box p {
    max-width: 90%;
    height: 155px;
    font-family: myfontRegular;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0, 0, 0);
    overflow: hidden;
    padding-left: 10px;
    margin-bottom: 30px;
}
.slider-doc-txt {
  padding-top: 0px;
  padding-right: 30px;
  padding-left: 10px;
  width: 100%;
  height: 100%;
}
.slider-doc-txt p{
  font-size: 12px;
}
.slider-doc-txt h1,.video-cont h1{

  max-height: 150px;
  font-size: 20px;
  overflow: hidden;
}
.video{
  width: 100%;
  height: 200px;
}
.news-cont-flex-box h1{
  font-size: 20px;
}
.news-cont-flex-box{
  padding-bottom: 20px;
}
.copyright p{
  font-size: 10px;
}
.copyright img{
  float: right;
    height: 20px;
    padding-right: 10px;
    margin-top: 15px;
}
.copyright{
  height: 40px;
  padding-top: 0px;
}
.news-cont-flex2{
  display: none;
}
.news-cont-flex3{
  display: none;
}
.foot-f1 p,.foot-f2 p,.foot-f3 p{
  font-size: 24px;
}
.foot-f1 li,.foot-f2 li,.foot-f3 li{
  line-height: 2;
  height: 100%;
  font-size: 15px;
}
.email-address{
  max-height: 32px;
  min-width: 220px;
}
.submitBtn{
  max-height: 32px;
  min-width: 65px;
font-size: 15px;
}

}

@media screen and (max-width: 700px){
  .copyright {
    height: 50px;
    padding-top: 0px;  
}
.copyright p {
  font-size: 10px;
  height: 0px; 
  margin-top: 20px !important;
}
.copyright img {
  float: right;
  height: 20px;
  padding-right: 10px;
  margin-top: 15px;
}
.news-cont-flex-box p{
  height: 140px;
  font-size: 18px;
}
.news-cont-flex-box h1{
  font-size: 20px;
}
}

@media screen and (max-width: 380px){
  .email-address{
    display: block;
    margin-bottom: 5px;
  }
  .submitBtn{
    display: block;
    margin: 0 ;
  }
}
@media screen and (max-width: 500px){
  .box1-cont h1{
    font-size: 1.5em;
  }
  .foot-f3 p{
    height: auto;
  }
  .footBox h1 img{
    height: 45px;
  }
  .foot-pics{
    font-size: 25px;
  }
  .news-cont-head h1{
    font-size: 2em;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .news-cont-flex-box h1{
    font-size: 1em;
  }
  .news-cont-flex-box{
    width: 100%;
  }
  .boxTxt{
    font-size: 4.5vw;
  }
  .box1-cont-flex-box{
    height: 150px;
  }
  .news-box{
    height: 700px;
  }
  .news-btn2 img{
    height: 30px;
  }
  .news-cont-flex-box p{
    font-size: small;
    max-width: 90%;
  }
  .news-btn1 img{
    height: 30px;
  }
  .box1-cont h1 {
    padding-bottom: 0px;
}
}

@media screen and (max-width: 800px){
  .news-cont-flex p{
    height: 120px;
  }
}
@media screen and (min-width: 0px) and (max-width:650px){
  .compScr{
    display: none !important;
  }
  .tabScr{
    display: none !important;
  }
  .mobScr{
    display: block;
  }
  
}
@media screen and (min-width: 1600px){
.compScr{
  display: block;
}
.tabScr{
  display: none !important;
}
.mobScr{
  display: none !important;
}

}
@media screen and (min-width: 651px) and (max-width:1050px){
  .compScr{
    display: none !important;
  }
  .tabScr{
    display: block;
  }
  .mobScr{
    display: none !important;
  }
  }
@media screen and (min-width: 1049px) and (max-width: 1599px){
  .compScr{
    display: block !important;
  }
  .tabScr{
    display: none;
  }
  .mobScr{
    display: none !important;
  }
  }

.noVisible{
  opacity: 0 !important;
}


.Countdown{
  margin: 10px auto;
  padding-bottom: 20px;
  color:#FFFFFF;
  background: -webkit-radial-gradient(#14BF94, #0B333A);
  background: radial-gradient(#14BF94, #0B333A);
    border-radius: 90px;
    max-width:  550px;
}

.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong{
  font-size: 50px;
  margin-top: 5px;
}

.subscribe{
  width: 60%;

  max-width: 180px;
  height: 55px;
  border-radius: 100px;
  background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
  background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  border: none;
  outline-style: none;
  color: white;
}
@media (max-width: 610px) {
  .Countdown {
    margin: 10px auto;
    padding-bottom: 20px;
    color: #FFFFFF;
    background: -webkit-radial-gradient(#14BF94, #0B333A);
    background: radial-gradient(#14BF94, #0B333A);
    border-radius: 90px;
    margin-top: 10vw;
    max-width: 550px;
    width: 100%;
    font-size: 2.5vw;
    height: 18vw;
}
.subscribe{
  width: 60%;
  height: 40px;
  font-size: 14px;
  max-width: 180px;}
.Countdown-col-element strong {
  font-size: 7vw;
}
}

  .fbButtonText{
    display: none;
  }
.App {
  text-align: center;
}




.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.footer{
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 50px;
  padding-bottom: 10px;
  background-color: #202f39;
  color: white;
  text-align: left;
}
.footer ul li{
  cursor: pointer;
  list-style-type: none;
  font-family: myfontLight;
  font-size: 12px;
  color: #ffffffc4;
  padding-bottom: 3px;
  letter-spacing: 0.3px;
}
.footer ul li img{
  height: 20px;
  margin: 2px 15px 4px -35px;
}
.footer ul{
  /* padding: 0px; */
}

.footer-list-heads{
  /* font-family: Montserrat;
  font-weight: bolder; */
  font-size: 15px;
  color: #ffffff;
}
.footer-list-icon-ul{
  padding-left: 35px;
}
.footer-list-simple{
  padding-left: 0px;

}
.footer-list-textbox-input{    
  width: 195px;
  font-size: 10px;
  padding: 2px;
}
.footer-list-textbox-input{
  outline-style: none;
  border: none;
}
.footer-list-textbox-body{
  padding-top: 5px;
  width: 250px;
  height: 35px;
  border-radius: 25px;
  background-color: #ffffff;
}
.footer-list-textbox-sendarrow{
  height: 10px;
  width: 15px;
  background-color: #00d887;
  display: inline-block;
  -webkit-clip-path: polygon(0 44%, 64% 45%, 64% 21%, 100% 52%, 64% 83%, 64% 61%, 0 61%);
  clip-path: polygon(0 44%, 64% 45%, 64% 21%, 100% 52%, 64% 83%, 64% 61%, 0 61%);
}

.footer-list-textbox-img1{
  width: 18px;
  margin-left: 7px;
}
.footer-panacloudlogo-cont{
  width: 90px;
}
.footer-list-contactus-icons{
  height: 20px;
  margin-right: 7px;
}

.footer-panacloudlogo-cont b{
  font-size: 12px;
  margin-left: 10px;
  color: white;
}

.footer-list-subscribe-btn{
  font-size: 11px;
  color: #9becd7;
  padding: 8px 30px;
  border-radius: 25px;
  background-color: #1a9779;
  border: 1px solid #9becd7;
}
.footer a{
  color: #ffffffc4;
}
.footer a:hover{
  color: #ffffff7a;
}

.fake-connect{
  display: none;
}
@media(max-width:768px){
  .original-connect{
    display: none;
  }
  .fake-connect{
    display: block;
  }
}
.PresidentInitiative-cont{
    background-image: -webkit-linear-gradient(left, #e6dada , #dfedf0);
    background-image: linear-gradient(to right, #e6dada , #dfedf0);
}
.president_png{
    width: 90%;
}
.PresidentInitiative-heading-h1{
    font-family: myfontExtraBold;
    font-size: 60px;
    font-weight: bold;
    text-align: left;
    color: #2b2929;
}
.PresidentInitiative-png-bg{
    background-image: url(/static/media/presidentinitiativebgpng.6c0d529d.png);
    background-size: cover;
    background-position: bottom;
}
.PresidentInitiative-heading-h2{
    font-family: myfontRegular;
    font-size: 30px;
    text-align: left;
    color: #2b2929;
}
.PresidentInitiative-heading-p{
    font-family: myfontRegular;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #2b2929;
    min-height: 15vw;
    margin-top: 50px;
    /* text-align: justify; */
}
.PresidentInitiative-button-apply{
    border: none;
    outline-style: none;
    margin-top: -7px;
    color: white;
    padding: 11px 44px;
    font-size: 12px;
    border-radius: 5px;
    background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
}

.PresidentInitiative-button-status:active,
.PresidentInitiative-button-status:hover,
.PresidentInitiative-button-status:visited
{
    color: white;
}
.PresidentInitiative-10k b{
    white-space: nowrap !important;
    font-family: myfontExtraBold;
}
.PresidentInitiative-10k{
    font-size: 35px;
    margin-top: -15px;
}
.PresidentInitiative-AppRec{
    font-size: 8.5px;
    margin-top: -10px;
}
.PresidentInitiative-vr-line{
    display: inline-block;
    width: 2px;
    height: 45px;
    background-color:  #192a56;
}
.PresidentInitiative-table tr td{
    padding: 15px;
}
.PresidentInitiative-headings{
    padding-bottom: 50px;
    width: 85%;
}
@-webkit-keyframes zoominoutsinglefeatured {
    0% {
        -webkit-transform: scale(1,1);
                transform: scale(1,1);
    }
    50% {
        -webkit-transform: scale(1.1,1.1);
                transform: scale(1.1,1.1);
    }

    100% {
        -webkit-transform: scale(1,1);
                transform: scale(1,1);
    }
}
@keyframes zoominoutsinglefeatured {
    0% {
        -webkit-transform: scale(1,1);
                transform: scale(1,1);
    }
    50% {
        -webkit-transform: scale(1.1,1.1);
                transform: scale(1.1,1.1);
    }

    100% {
        -webkit-transform: scale(1,1);
                transform: scale(1,1);
    }
}




.single-featured-image-header  {
    -webkit-animation: zoominoutsinglefeatured 30s forwards;
            animation: zoominoutsinglefeatured 30s forwards;
   
}

.odometer-value{
    font-family: myfontExtraBold;
    margin-top: -2.5px;
    text-align: center;
}
.PresidentInitiative-10k{
    overflow: hidden;
    max-height:43px;
    margin-bottom: 9px;
    width: 90px;

}

.myfade  {
    -webkit-animation: myfade 1s ease-in-out;
            animation: myfade 1s ease-in-out;
    
}








@media (min-width: 800px) and (max-width: 1200px){
    .PresidentInitiative-heading-h1{
        font-size: 40px;
    }
    .PresidentInitiative-heading-h2{
        font-size: 24px
    }
    .PresidentInitiative-heading-p{
        font-size: 14px;
    }
}
@media (min-width: 400px) and (max-width: 799px){
    .PresidentInitiative-heading-h1{
        font-size: 30px;
    }
    .PresidentInitiative-heading-h2{
        font-size: 20px
    }
    .PresidentInitiative-heading-p{
        font-size: 12px;
    }
}
@media (max-width: 499px){
    .PresidentInitiative-heading-h1{
        font-size: 20px;
    }
    .PresidentInitiative-heading-h2{
        font-size: 15px
    }
    .PresidentInitiative-heading-p{
        font-size: 10px;
    }
}
@media (max-width: 575px){
    .president_png{
        margin-top: 20px;
        width: 30%;
        float: left;
    margin-left: 5%;
    }
}









.supporterIcons ul{
    list-style-type: none;
}
.supporterIcons ul li{
    float: left;
}
.supporterIcons ul li img{
    height: 5.5vw;
    /* height: 60px; */
    padding: 1vw 2vw;
}

.supportingCont{
    font-family: myfontRegular;
    /* font-size: 2vw; */
    font-size: 1.7em;
    font-weight: 600;
    /* text-align: left; */
    color: #017969;
}
.supportingBg{
      /* background-color: #f9f9f9; */
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
      background-image: -webkit-linear-gradient(right, #e6dada, #dfedf0);
      background-image: linear-gradient(to left, #e6dada, #dfedf0);
}

.SIimg1{
    margin-left: -35px;
}

@media (max-width: 610px) {
  


  }

@media (max-width: 1000px) {
  
    .supporterIcons ul li img{
        height: 8vw;
        /* height: 60px; */
    }
    .supportingCont{
        font-size: 1.5em;
    }
    
}

@media (max-width: 600px) {
  

    .supporterIcons ul li img{
        height: 8vw;
        /* height: 60px; */
    } .supportingCont{
        font-size: 1em;
    }
    
}
@media (min-width: 1400px) {
    .supporterIcons ul li img{
        height: 3em !important;
        /* height: 60px; */
        /* padding: 1vw 2vw; */
    }
  }
@media (min-width: 1000px) {
    .supporterIcons ul li img{
        height: 2.9em !important;
        /* height: 60px; */
        /* padding: 1vw 2vw; */
    }
  }
  .SupportingPartners-heading{
      font-family: myfontExtraBold;
      letter-spacing: 3.3px;
      color: #192a56;
  }

/* *{
  transition: 1s;
} */

.AvailPrograms{
  margin-top: -160px;
  background-color: transparent;
  /* height: 600px; */
  padding-bottom: 30px;
}
@media screen and (max-width: 1250px){
  .AvailPrograms{
    margin-top: -100px;
  }
}
.AvailPrograms-heading{
  text-align: right;
  font-family: myfontBold;
  color: #666666;
}

@media screen and (max-width: 950px) and (min-width: 768px){
  .avail-pro-con{
    padding: 5px !important;
  }

  .new-program-title{
    font-size: 1.5vw;
  }
}
@media screen and (max-width: 950px){
  .AvailPrograms{
    margin-top: 0px;
  }
  
  .AvailPrograms-heading{
    text-align: center;
  }
}

@media screen and (min-width: 1500px){
  .AvailPrograms-heading{

    letter-spacing: 18px;
    font-size: 28px;
  }
}
@media screen and (max-width: 1499px){
  .AvailPrograms-heading{
    letter-spacing: 1.2vw; 
    font-size: 2.1vW;
  }
}
.AvailProgram-programbox-section{
  padding-left: 4%;
  padding-right: 4%;
}
.AvailPrograms-flex-box{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  max-height: 390px;
  width: 30vw;
  height: 30vw;
  border-radius: 5px;
}

/* .AvailProgram-programbox-cont-hover-section{
  margin-top: -74%;
  width: 100%;
  height: 100%;
} */

.AvailProgram-programbox-cont{
  min-width: 225px;
  width: 95%;
  height: 190px;
  border-radius: 7.5px;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: transparent;
  overflow: hidden;
}

.AvailProgram-programbox-cont-title{
  background-color: #fdfdfd;
}
@media screen and (max-width: 990px){
  .AvailProgram-programbox-cont{
    min-width: 230px;
    max-width: 400px;
  }

}
@media screen and (max-width: 850px){
  .AvailProgram-programbox-cont{
    height: 150px;
    font-size: 13px;
  }
  .AvailPrograms-heading {
    letter-spacing: 13px;
    font-size: 20px;
}
}
@media screen and (max-width: 550px){
  
  .AvailPrograms-heading {
    letter-spacing: 2.2vw;
    font-size: 3.5vw;
}
}

.AvailProgram-programbox-cont-ii .AvailProgram-programbox-cont-header{
  background-color: #192a56;
}
.AvailProgram-programbox-cont-ii{
  color: #192a56;
}

.AvailProgram-programbox-cont-cn .AvailProgram-programbox-cont-header{
  background-color: #e1006a;
}
.AvailProgram-programbox-cont-cn{
  color: #e1006a;
}

.AvailProgram-programbox-cont-bc .AvailProgram-programbox-cont-header{
  background-color: #fbc314;
}
.AvailProgram-programbox-cont-bc{
  color: #fbc314;
}


.AvailPrograms-programsInDev-heading{
    text-align: left;
    font-family: myfontBold;
    letter-spacing: 7px;
    font-size: 26px;
    color: #666666;
}
@media screen and (max-width: 650px){
  .AvailPrograms-programsInDev-heading{
    letter-spacing: 5px;
    font-size: 22px;
}
}
@media screen and (max-width: 455px){
  .AvailPrograms-programsInDev-heading{
    letter-spacing: 2px;
    font-size: 18px;
}
}
.AvailPrograms-programsInDev-section{
  padding-left: 5%;
  padding-top: 30px;
  -webkit-transition: 2s;
  transition: 2s;
}

.AvailPrograms-programsInDev-content{
  scroll-behavior: smooth;
}
.AvailPrograms-programsInDev{
  width: 100%;
}
.AvailPrograms-programsInDev-content{
 width: auto;
 white-space:nowrap; 
 overflow-y: hidden;
}

.AvailPrograms-programsInDev-content::-webkit-scrollbar{
  display: none;
}

.AvailPrograms-programsInDev-box{
  color: white;
  display: inline-block;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background: -webkit-linear-gradient(left, #9a9a9a, #2e2e2e);
  background: linear-gradient(to right, #9a9a9a, #2e2e2e);
  /* background: gray; */
  margin-right: 50px;
  width: 300px;
  height: 150px;
  overflow: hidden !important;
  /* padding: 0px 20px; */
  /* transition: all 2s;   */
}
.AvailPrograms-programsInDev-box div{
  -webkit-transition: all 1s;
  transition: all 1s;
  padding: 0px 10px 0px 15px;
}




.innerText{
  font-family: myfontLight;
  letter-spacing: 8px;
  white-space: normal;
}
@media screen and (max-width: 575px){
 
.innerText{
  width: 100%;
  font-size: 14px;
  letter-spacing: 5px;
  white-space: nowrap;
  overflow: hidden;
}
}
/* 
.slick-dots > li:first-child button:before{
  background-color: black;
  height: 20px;
  width: 20px;
  -webkit-clip-path: polygon(100% 8%, 45% 51%, 100% 92%, 86% 100%, 18% 53%, 85% 0);
  clip-path: polygon(100% 8%, 45% 51%, 100% 92%, 86% 100%, 18% 53%, 85% 0);
}

.slick-dots > li:last-child button:before{
  background-color: black;
    height: 20px;
    width: 20px;
    -webkit-clip-path: polygon(12% 0, 74% 51%, 10% 100%, 0 90%, 52% 51%, 0 10%);
    clip-path: polygon(12% 0, 74% 51%, 10% 100%, 0 90%, 52% 51%, 0 10%);
} */
/* 
.slick-dots{
  top: 180px;
} */

.slick-arrow{
  display: none !important;
}


.controls{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controls__item{
  opacity: .2;
  background: 0 0;
  box-shadow: none;
  border: none;
}
.page-indicators {
  display: flex;
}
.controls__item {
  opacity: .2;
  background: 0 0;
  box-shadow: none;
}
.page-indicators__item {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: .2rem;
  opacity: .4;
  background: 0 0;
  border: none;
  box-shadow: none;
}
.page-indicators__item__icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #525c65;
}


.new-program-box{
  border-radius: 5px;
  min-height:180px;
  max-width: 350px;
  background: white;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  cursor: pointer;
  margin-bottom:20px;
  width: 100%;
}
.new-program-box::before{
  content: "";
  height:100%;
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  border-radius: 5px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.new-program-box:hover::before{
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.new-bc-box:hover::before{
  background-color: #fbc314;
}
.new-ai-box:hover::before{
  background-color: #192a56;
}
.new-cn-box:hover::before{
  background-color: #e1006a;
}
.new-iot-box:hover::before{
  background-color: #1b8f8b;
}
.new-hover-data{
  height:100%;
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.new-hover-data strong{
  font-family: myfontBold;
  text-align: left;
  padding-left: 8px;
}
.new-hover-data p{
  font-size:14px;
  text-align: left;
  padding: 10px 10px;
  /* word-break: break-all; */
}
@media screen and (max-width: 1200px){
  .new-hover-data p{
    font-size:12px;
  }
}
.new-program-box:hover .new-upper-bar,.new-program-box:hover .new-program-title{
  opacity: 0;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.new-program-box:hover{
  opacity:0.8;
}

.new-program-box:hover .new-hover-data{
  opacity:1;
  -webkit-transform:scale(0.8,0.8);
          transform:scale(0.8,0.8);
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;
}
.new-hover-data{
  -webkit-transform:scale(0.5,0.5);
          transform:scale(0.5,0.5);
    opacity:0;
  -webkit-transition: all ease-in-out 0.5s;
  transition: all ease-in-out 0.5s;

  }


.new-upper-bar{
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.new-ai-bar{
  background-color: #192a56;
}
.new-ai-title{
  color: #192a56;
}
.new-cn-bar{
  background-color: #e1006a;
  -webkit-transition: all 2s;
  transition: all 2s;
}
.new-cn-title{
  color: #e1006a;
}
.new-bc-bar{
  background-color: #fbc314;
  -webkit-transition: all 2s;
  transition: all 2s;

}
.new-bc-title{
  color: #fbc314;
}

.new-iot-bar{
  background-color: #1b8f8b;
  -webkit-transition: all 2s;
  transition: all 2s;

}
.new-iot-title{
  color: #1b8f8b;
}
.new-program-title{
  white-space: nowrap;
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%,-60%);
          transform: translate(-50%,-60%)
}



.AvailPrograms-programsInDev-hr-line{
  width: 100px;
  height: 3px;
  background-color: black;
}


.AvailProgram-programbox-cont-hover-section{
  /* margin-top: -74%; */
  width: 100%;
  height: 100%;
  /* display: none; */
  position: absolute;
  opacity: 0;
  /* background-color: #a52a2a7a; */
}

.AvailProgram-programbox-cont-hover-text{
width: 90%;
max-height: 80%;
/* background-color: purple; */
}

.AvailProgram-programbox-cont-hover-text h4{
    font-family: myfontRegular;
    font-size: 12px;
    text-align: left;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
    color: #ffffff;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;

    
}
.AvailProgram-programbox-cont-hover-section:hover h4{
-webkit-transform: translateY(0px);
        transform: translateY(0px);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.AvailProgram-programbox-cont-hover-text p{
  font-family: myfontLight;
  font-size: 10px;
  text-align: left;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  color: #ffffff;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
.AvailProgram-programbox-cont-hover-section:hover p{
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
  }
.AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-header, .AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-title,
.AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-header, .AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-title,
.AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-header, .AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-title
{
  /* display: none !important; */
  opacity: 0;
  /* opacity: 4.9; */
}

.AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-hover-section,
.AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-hover-section,
.AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-hover-section
{
  display: flex;
  opacity: 0.8;
}
.AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-hover-section{
  background-color: #192a56;
}
.AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-hover-section{
  background-color: #e1006a;
  -webkit-transition: all 2s;
  transition: all 2s;

}
.AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-hover-section{
  background-color: #fbc314;
  -webkit-transition: all 2s;
  transition: all 2s;

}



.AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST:hover,
.AvailPrograms-programsInDev-box-ENTREPRENEURSHIP:hover,
.AvailPrograms-programsInDev-box-SDN-5G:hover,
.AvailPrograms-programsInDev-box-INTERNET-OF-THINGS:hover,
.AvailPrograms-programsInDev-box-ANDROID:hover,
.AvailPrograms-programsInDev-box-AUGMENTED-REALITY:hover,
.AvailPrograms-programsInDev-box-IOS:hover
{
  /* background-image: none; */
  cursor: pointer;
  -webkit-transition: 1s;
  transition: 1s;
}

.AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST:hover>div{
  background-color: #01bcd4;
}
.AvailPrograms-programsInDev-box-ENTREPRENEURSHIP:hover>div{
  background-color: #000000;

}
.AvailPrograms-programsInDev-box-SDN-5G:hover>div{
  background-color: #f12742;
}
.AvailPrograms-programsInDev-box-INTERNET-OF-THINGS:hover>div{
  background-color: #1b8f8b;
}
.AvailPrograms-programsInDev-box-ANDROID:hover>div{
  background-color: #a4c639;
}
.AvailPrograms-programsInDev-box-AUGMENTED-REALITY:hover>div{
  background-color: #624d97;
}
.AvailPrograms-programsInDev-box-IOS:hover>div{
  background-color: #696969;
}


/* .AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ENTREPRENEURSHIP,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-SDN-5G,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-INTERNET-OF-THINGS,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ANDROID,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-AUGMENTED-REALITY,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-IOS
{
  background-image: none;
  cursor: pointer;
  transition: 2s;
}

.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST{
  background-color: #01bcd4;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ENTREPRENEURSHIP{
  background-color: #000000;

}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-SDN-5G{
  background-color: #f12742;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-INTERNET-OF-THINGS{
  background-color: #1b8f8b;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ANDROID{
  background-color: #a4c639;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-AUGMENTED-REALITY{
  background-color: #624d97;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-IOS{
  background-color: #696969;
} */



.PresidentSaysVideo-bg{
  background-color: #2b2929;
  /* height: 500px; */
  padding: 0 4% 50px 4%;
}

.PresidentSays-heading{
  font-size: 42px;
  text-align: left;
  color: #ffffff;
  font-family: myfontBold;
  padding-bottom: 20px;
  padding-top: 50px;
}

.PresidentSays-video-list{
  /* min-width: 400px; */
  padding-top: 20px;    
  padding-bottom: 20px;
  max-height: 480px;
  height: 480px;
  background-color: #484848;
  overflow-y: scroll;
}
.PresidentSays-video-list::-webkit-scrollbar{
  width: 10px;
   background-color: #414141;
  /* background-color: #05d88c; */
}
.PresidentSays-video-list::-webkit-scrollbar-thumb{

  background-color: #05d88c;
}

.PresidentSays-video-list-image{
  display: inline-block;
  width: 50%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.PresidentSays-video-list-content{
  display: inline-block;
  width: 50%;
  padding-left: 1.5%;
}
.PresidentSays-video-list ul{
  padding-left: 2%;
  padding-right: 2%;
}
.PresidentSays-video-list ul li{
  cursor: pointer;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.PresidentSaysVideo-video-list-flexbox{
  width: 100%;
  display: flex;
  height: 100px;
}





.PresidentSays-video-list-content h4{
  font-family: myfontRegular;
    font-size: 13px;
    text-align: left;
    color: #9becd7;
    margin: 0 0 4px 0;
    display: -webkit-box;
    max-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
}
.PresidentSays-video-list-content p{
  max-height: 32px;
  overflow: hidden;
  font-family: myfontLight;
  font-size: 10px;
  /* line-height: 1.36; */
  /* letter-spacing: -0.3px; */
  text-align: left;
  color: #ffffff;
}

.PresidentLaunchingWeb-content-video-h2{
      /* width: 217px; */
    /* height: 16.5px; */
    font-family: myfontRegular;
    font-size: 20px;
    /* font-weight: bold; */
    /* font-style: normal; */
    /* font-stretch: normal; */
    /* line-height: 1.38; */
    /* letter-spacing: -0.6px; */
    text-align: left;
    color: #9becd7;
}
.PresidentLaunchingWeb-content-video-p{
  max-width: 500px;
  max-height: 35px;
  text-align: justify;
  float: left;
  font-family: myfontLight;
  font-size: 12px;
  color: #ffffff;
  margin: 0 0 4px 0;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
}

.PresidentLaunchingWeb-video-col, .PresidentLaunchingWeb-video-desc-col, .PresidentLaunchingWeb-video-list-col{
  padding-left: 5px;
  padding-right: 5px;
}

/* @media (max-width: 1221px){
  .PresidentLaunchingWeb-video-list-col{
      max-width: 400px;
  }
} */
@media (max-width: 991px){
  .PresidentSays-video-list-image{
      max-width: 20%;
      min-width: 100px;
  }
  .PresidentSaysVideo-video-list-flexbox{
    height: 10vw;
    min-height: 55px;
  }

  .PresidentLaunchingWeb-video-iframe{
    height: 50vw;
  }
  .PresidentSays-video-list{
    height: auto;
  }
}
.PresidentSaysVideo-video-list-onVideo{
  color: #04c492 !important;
    text-decoration: underline;
}
.PresidentLaunchingWeb-bg{
  background-color: #00bf9a;
  /* height: 500px; */
  padding-bottom: 100px;
}

.PresidentLaunchingWeb-content-section{
  width: 99%;
  /* height: 400px; */
  /* background-color: blue; */
}
@media (min-width: 400px){
  .PresidentLaunchingWeb-content-section{
    /* width: 98%; */
    /* height: 400px; */
    /* background-color: blue; */
  }
}
@media (max-width: 1600px){
.PresidentLaunchingWeb-container{
  padding-left: 4%;
  padding-right: 4%;
}
}

.PresidentLaunchingWeb-image{
  width: 100%;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.slick-track{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.PresidentLaunchingWeb-row{
  /* height: 400px; */
}

.PresidentLaunchingWeb-text h3{
  /* width: 238.5px;
  height: 24.5px; */
  font-family: myfontBold;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: -0.8px;
  text-align: left;
  color: #2b2929;
}

.PresidentLaunchingWeb-text p{
  font-family: myfontLight;
  font-size: 12px;
  letter-spacing: 0.6px;
  text-align: justify;
  color: #ffffff;
}
.PresidentLaunchingWeb-content-heading{
  /* padding-left: 1%; */
  font-size: 42px;
  text-align: left;
  color: #ffffff;
  font-family: myfontBold;
  padding-bottom: 20px;
  padding-top: 50px;
}

@media (min-width: 1000px){
  .PresidentLaunchingWeb-text p{
    font-size: 15px;
  }
  .PresidentLaunchingWeb-text h3{
    font-size: 27px;
  }
}

.slick-dots{
  bottom: -40px !important;
}
.slick-dots li button:before{
  font-size: 12px !important;
     opacity: 1; 
    color: white !important;
}
.slick-dots li{
  margin: 0px -1px !important;
}


.my-container-2{
    position: fixed;
    z-index:111114;
    top: 0;
    height:100vh !important;
    background: -webkit-linear-gradient(left, #e6dada, #dfedf0);
    background: linear-gradient(to right, #e6dada, #dfedf0);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.my-main img{
    width: 200px;
    height:200px;
    margin: auto;
}
.piaic-logo{
    display: block;
}
.my-text{
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #017969;
    margin-bottom: 50px !important;
  
}
.Rectangle-109 {
    padding: 20px 60px;
    color: white !important;
    cursor: pointer;
    border-radius: 100px;
    background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  }
  .Rectangle-109:hover{
      color: white;
  }
  .slide{
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
    -webkit-transition: all ease-in 1s;
    transition: all ease-in 1s;
  }
 

    
.bg-modal {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.proffesional-list li{
  margin: 10px 0px;
}

.aboutCont{
  background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  min-height: 90vh;
}
.whiteCir3{
  width: 136px;
  height: 136px;
  margin: 7px;
  border-radius: 50%;
  position: absolute;
  /* background-color: purple; */
  background-image: url(https://gotest.pk/wp-content/uploads/2015/09/Imran-Khan-Bio.jpg);
  background-size: cover;
  background-position: center;
}

.close {
    float: none;
  font-size: 42px;
  color: white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  
}

.cardArea{
    /* box-shadow: 0px 0px 40px -15px lightgrey; */
    width: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .cardArea h1{
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    color: #33322d;
  }
  
  .cardArea p{
    font-family: Montserrat;
      font-size: 12px;
      color: #33322db5;
  }

  .modal-contents::-webkit-scrollbar {
    display: none;
    }
.modalBtn2 {
    width: 120px;
    font-size: 12px;
    height: 40px;
    border-radius: 100px;
    background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
    color: white;
    border: none;
    outline-style: none;
  }

  .modalPCont p{
      max-width: 1000px;
      width: 80%;
  }
  .modalPCont {
      width: 100%;
}

.closeBtnBox{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    color: #333;
    cursor: pointer;
    background-color: #08d88d;
}
.Rectangle-58 {
    width:100% !important;
    background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
    background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
    display: flex;
    justify-content: center;
  }

  .Rectangle-59::-webkit-input-placeholder{
    color: #00000063;
  }

  .Rectangle-59::-ms-input-placeholder{
    color: #00000063;
  }

  .Rectangle-59::placeholder{
    color: #00000063;
  }
  .disable-btn{
    background: lightgray!important;
    cursor: not-allowed!important;
  }
  #myForm{
      padding:5% 0;
  }
  select:focus,input:focus{
     box-shadow: none !important;
  }

  button:focus{
      outline: none;
  }
  .captcha{
    display: flex;
    margin: 30px 0px;
    justify-content: center;
  }
  .additionalData{
    font-size: 11px
  }
  #container-check{
    margin:3% 0px -0.5% 0;

  }

  #dl{
    vertical-align: middle;
    margin: 0px 10px;
  }
  .check-message{
    display: block;
    white-space: pre-wrap;
    width: 550px;
    color: #017969;    
    margin-top:2px !important;
    font-size:10px !important;
  }
  .cityOfStudy-message{
    display: block;
    white-space: pre-wrap;
    width: 550px;
    color: #f80909;    
    margin-top:2px !important;
    font-size:13px !important;
  }
  .regClosed-message{
    display: block;
    text-align: center;
    white-space: pre-wrap;
    width: 550px;
    color: #f80909;    
    margin-top:2px !important;
    font-size:25px !important;
  }

  .regClosed-message-hidden{
    display: none;
    text-align: center;
    white-space: pre-wrap;
    width: 550px;
    color: #f80909;    
    margin-top:2px !important;
    font-size:25px !important;
  }

  /* Customize the label (the container) */
.container-check{
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #039e89;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
} 
  
  .my-error{
    display: block;
    text-align: center;
    color: rgb(165, 0, 0);
    margin: auto;
    white-space: pre-wrap;
    width: 550px;
  }
  
  .APPLICATION-FORM {
    font-size: 30px;
    font-weight: 600;
    margin-bottom:10%; 
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #017969;
  }
 
  .label {
    font-size: 18px;
    margin:3% 0;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #017969;
  }

  .Rectangle-59 {
    width: 553.8px;
    height: 55px;
    font-size: 15px !important;
    border-radius: 5px;
    background-color: #ffffff;
  }
  .radio-label{
      display: inline-block;
  }
  .radioLabel{
    color: #017969;
    cursor: pointer;
  }
  .radio-group{
      display: inline-block;
      margin-left:10%;
  }
  .Ellipse-13 {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
    left: 5px;
  }
  .Rectangle-60 {
    width: 553.8px;
    height: 60px;
    margin: 4% 0px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    background-color: #00ce9f;
  }
  .Rectangle-62 {
    width: 400px;
    height: 60px;
    border: none;
    cursor: pointer;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius:0px;
    color: white;
    background-color: #00ce9f;
  }
  .Rectangle-63 {
    width: 400px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius:0;
    background-color: #ffffff;
    display: inline-block;
  }
  .-File-type-jpg-jpeg-png {
    margin:0 auto;
   padding: 5px 50px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #00ce9f;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background:#fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiUlEQVQ4jd3OsQnCABCF4U9sRHECcYI4gTiBbqAbCCHZQCcQOztxAcEFhHQBB7JOk0CQmMTY5cEVd/f+d0cvNP6DncCrIzxEClM8OwQkxQcwx/0H+IHZ53CBawv4huDbcoVTDXzGsunCBoeK+RHrJrjQDlGpj7GtMg5qQvZ4554RLm2vlxXm1WdlHBEOAFsurDwAAAAASUVORK5CYII=)  97% 50% no-repeat;
    cursor: pointer;
  }
  
  [type="date"] {
    background:#fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiUlEQVQ4jd3OsQnCABCF4U9sRHECcYI4gTiBbqAbCCHZQCcQOztxAcEFhHQBB7JOk0CQmMTY5cEVd/f+d0cvNP6DncCrIzxEClM8OwQkxQcwx/0H+IHZ53CBawv4huDbcoVTDXzGsunCBoeK+RHrJrjQDlGpj7GtMg5qQvZ4554RLm2vlxXm1WdlHBEOAFsurDwAAAAASUVORK5CYII=)  97% 50% no-repeat;
    cursor: pointer;
  }
  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  .placeholder-headshot {
    width: 158px;
  }
  .img-container{
    display: inline-block;
    float: left;
    height:211px;
    width:150px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-top-left-radius:4px;
    border-bottom-left-radius: 4px; 
  }
  #swal2-content{
    font-size: 14px;
    font-weight: bold;
    text-align: justify !important;
  }
   .swal2-confirm{
     border-radius: 100px !important;
     background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3) !important;
     background-image: linear-gradient(to left, #08d88d, #0eb5a3) !important;
     border: none !important;
     padding:10px 80px !important;
     outline-style: none !important;
     color: white;
   }
   
   .swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}
   
   
  
    input[type="radio"] {
      display:none;
  }

  input[type="radio"] + span {
      display:inline-block;
      width:15px;
      height:15px;
      margin:-1px 4px 0 4px;
      vertical-align:middle;
      cursor:pointer;
      border-radius:  50%;
  }
  
  input[type="radio"] +  span {
       border: 1px solid #017969;
  }
  
  input[type="radio"]:checked +  span{
       background-color:#039e89;
  }
  
  input[type="radio"] +  span,
  input[type="radio"]:checked +  span {
    -webkit-transition:background-color 0.4s linear;
    transition:background-color 0.4s linear;
  } 






.error{
    color: rgb(172, 15, 15);
    position: relative;
    font-size: 15px;
    margin:10px 0;
    text-align: justify;
    width: 552px;
    padding: 5px;
    -webkit-transition: all ease-in-out 2s;
    transition: all ease-in-out 2s;
}


.errorElem{
    border: 1px solid rgba(172, 15, 15, 0.589) !important;
}

.min-height100vh-120px{
  min-height: calc(100vh - 125px);
}

@media (max-width: 1149px){
.min-height100vh-120px{
  min-height: calc(100vh - 120px);
}
}

@media (max-width:585px){
    .error{
        font-size: 16px;
        width: 90vw !important;
        margin: auto;
    }
    .APPLICATION-FORM{
      font-size: 1.8rem !important;
      margin: 30px auto;
    }
    .cityOfStudy-message{
      width:85vw !important;
      margin: auto;
    }
    .Rectangle-58{
        display: block;
        justify-content: unset;
        width:100vw !important;
        margin: 0;
        padding: 0;
      text-align: center;

        background: -webkit-linear-gradient(top, #dfedf0, #e6dada);

        background: linear-gradient(to bottom, #dfedf0, #e6dada);
    }
    .label{
        display:block; 
        width: 90vw !important;
        margin:3% auto !important;
        font-size: 14px !important;
    }
    .Rectangle-59{
        width: 90vw !important;
        margin: auto !important;
        height:50px;
    }
    .Rectangle-78{
      width: 90vw !important;
        margin: auto;        
    }
    .Rectangle-63{
      width: 90vw !important;
        border-radius:5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0; 
        margin: auto; 
    }
    .Rectangle-62{
        width: 100%;
        border-bottom-left-radius: 5px;

    }
  
    .img-container{
        width: 90vw;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; 
    }
    .-File-type-jpg-jpeg-png {
        font-size: 12px;
    }
    .Rectangle-60{
        width: 90vw;
        display: block;
        margin: 20px auto;
    }
    .check-message{
      width: 90vw;
      margin: 20px auto;
      text-align: justify;
    }
}


.bg-modal2 {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111113;
}
.ReactCrop__image{
  max-height: 60vh !important;
}
.modal-contents2 {
  /* height: 90%;*/
  width: 90%; 
  background-color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 10px;
}

.selectImageButton{
  width: 100%;
  height: 60px;
  margin: 4% 0px;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 5px;
  background-color: #00ce9f;
}
.close2 {
    float: none;
  font-size: 42px;
  color: white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  
}

.cardArea{
    /* box-shadow: 0px 0px 40px -15px lightgrey; */
    width: 250px;
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }
  .cardArea h1{
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    color: #33322d;
  }
  
  .cardArea p{
    font-family: Montserrat;
      font-size: 12px;
      color: #33322db5;
  }

  .modal-contents::-webkit-scrollbar {
    display: none;
    }
.modalBtn2 {
    width: 120px;
    font-size: 12px;
    height: 40px;
    border-radius: 100px;
    background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
    color: white;
    border: none;
    outline-style: none;
  }

  .modalPCont p{
      max-width: 1000px;
      width: 80%;
  }
  .modalPCont {
      width: 100%;
}

.closeBtnBox2{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    color: #333;
    cursor: pointer;
    background-color: #08d88d;
}
.cl-b{
    background: #e1006a !important;
}
@media (min-width: 850px) {
    .cl-b .Artificial-Intelligence {
        font-size: 3em !important;
    }
  }

.cl-b .Artificial-Intelligence{
    white-space: pre-wrap;
    text-shadow: none;
    font-size: 6vw;
}
.cl-b .line{
    text-shadow: none;

}
.cl-b+.line2{
    color: #e1006a;
}
.c1 .quarter{
color: #e1006a;
}
.c1 .para{
    color: #d6a6bd;
}
.c2 {
    background:rgba(224, 0, 104,.5);
    color: white;  
}
.c3 {
    background:rgba(224, 0, 104,.75);
    color: white;  
}
.c4 {
    background:#e1006a;
    color: white;  
}

.sec-head-2{
    text-align: center;
    color: #e1006a;
    margin-bottom: 5vw;
  }
.cl-1-t{
    border:2px solid #e1006a !important;
    color: #e1006a !important;
}
.cl-2-t{
    background:  #EF7EB3 !important;
}

.cl-3-t{
    background:  #E83F8F !important;
}

/* .my-line{
    display: block;
    position: absolute;
    top:14vw;
    left: 50%;
    transform: translateX(-50%);
    height: 85%;
    width: 4px;
    opacity:0.05 !important;
    background: #192a56;
} */
.cl-cr{
    background: #E83F8F !important;
}

@media (max-width:1000px){
    .my-line{
      display: none;
    }
}

  
     .quarter-row{
       margin: 20px 0;
     }
   
   @media (min-width:1400px){
     .my-container{
       min-width: 90vw !important;
      }
  
      .Rectangle-68{
        padding: 0px 20px;
      }
     
      .pack-right{
        justify-content: flex-end;
        
      }
      .pack-left{
        justify-content: flex-start;
      }
      .box-container {
        display: inline-block;
        width: 30vw !important;
        padding:  40px;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.26);
        background-color: #ffffff;
        cursor: pointer;
      }
      .box-container:hover{
        box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.192);
      }
      
      
      .circle{
        width: 3.5vw !important;
        height:3.5vw !important;
      }
      .verticle-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        display: inline-block;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
      }
      .heading{
        font-size:1.2vw !important;
      }
      .para-data{
        font-size: .7vw !important;
      }
      

    }
    
    
    
    
    .Artificial-Intelligence-Page {
        width: 100%;
        background-color: #ffffff;
      }
      
      
      .Rectangle-67 {
        width:100%;
        height: 90vh;
        background-color: #192a56;
      }
      .SliderData{
          color: White;
          /* height:100%; */
          /* padding: 35vh 0; */
          width: 100%;
          text-align: center;
          white-space: nowrap; 
        text-align: center;
      
      }
      .program{
        margin-bottom: 50px;
      }
      
      .Artificial-Intelligence {
        font-size: 5vw;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: normal;
      }
      .line{
        white-space: pre-line;
        font-size: 1vw;
        font-weight: 600;
        width: 70vw;
        margin: auto;
        word-spacing: 1px;
        letter-spacing: normal;
        text-align: center;
      }
      .line2{
        white-space: pre-line;
        padding: 10vh 0;
        font-size: 1.3vw;
        font-weight: 600;
        width: 40vw;
        margin: auto;
        word-spacing: 1px;
        letter-spacing: normal;
        text-align: center;
        color: #192a56;
      }
      .Q-Con{
          padding: 0px 50px;
      }
      .Rectangle-68 {
        color: white;
        width: 100%;
        font-size: 1vw;
        height:20vw;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.521);
        
      }
      .d1{
        background-color: #ffffff;
        color: #192a56 !important;
      }
      .d2{
        background-color: rgba(25, 42, 86, 0.5);
      }
      .d3{
        background-color: rgba(25, 42, 86, 0.75);
      }
      .d4{
        background-color: #192a56;
      }
      .quarter{
        font-size: 2vw;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
      }
      .para{
        font-size: 1vw;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: center;
      }
      .box-mega-container{
        padding: 50px 35px;
      }
      hr{
        margin: 0px 0px 50px 0px;
      }
      .circle {
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        text-align: center;
        z-index:11111;
      }
      .circle span{
        color: white;
      }
      .horizontal-circle {
        width: 100%;
        padding: 20px 0px;
        display: flex;
        justify-content: center;  
        text-align: center;
      }
      .verticle-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        display: inline-block;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
      }
      .verticle-circle .circle{
       /* margin-top:120%;  */
      }
      .B1{
        margin-top: 190% !important;
      }
      
      .pack-container{
        display: flex;
      }
      .pack-right{
        justify-content: flex-end;
      }
      .pack-left{
        justify-content: flex-start;
      }
      .box-container {
        width: 40vw;
        display: inline-block;
        padding:  40px;
        border-radius: 5px;
        -webkit-backdrop-filter: blur(30px);
        backdrop-filter: blur(30px);
        box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.26);
        background-color: #ffffff;
        cursor: pointer;
        -webkit-transition: all 1s;
        transition: all 1s;
      }
      .box-container:hover{
        box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.192);
        -webkit-transition: all 1s;
        transition: all 1s;
      }
      @media (max-width:1300px){
        .ai-circle-line{
          height: 88% !important;
        }
      }
      @media (max-width:1399px){
        .circle-line{
          height: 85% !important;
        }
        .bc-circle-line{
          height: 92% !important;
        }
      }
      .bc-circle-line{
        display: block;
        position: absolute;
        top:5vw;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 92%;
        width: 4px;
        /* opacity:0.05 !important; */
        background: #fde182;
        margin-top: -4%;
      }
      .ai-circle-line{
        display: block;
        position: absolute;
        top:13vw;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 87%;
        width: 4px;
        /* opacity:0.05 !important; */
        background: #192a56;
        margin-top: -4%;
      }
      .circle-line{
        display: block;
        position: absolute;
        top:14.4vw;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 87% !important;
        width: 4px;
        /* opacity:0.05 !important; */
        background: #e20069;
        margin-top: -4%;
      }
      @media (max-width:1000px){
        .circle-line,.verticle-circle,.ai-circle-line{
          display: none;
        }
      }
      .curr{
        width: 220px;
        height: 60px;
        border-radius: 5px;
        margin-left: 5px;
        margin-right: 5px;
        background-color:  #192a56;
      }
      .apply{
        width: 220px;
        height: 60px;
        border-radius: 5px;
        margin-left: 5px;
        margin-right: 5px;
        background-color:  #00ce9f;
      }
      .btn-Grp{
        display: flex;
        justify-content: center;
      }
      
      
      .horizontal-circle{
        display: none;
      }
      .heading{
        color: #192a56;
        font-size:16px;
        font-weight: bold;
      }
      .para-data{
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: justify;
        /*word-break: break-all;*/
        word-spacing: 1px;
        color: #192a56;
      }
      .sec-head{
        text-align: center;
        color: #192a56;
        margin-bottom: 5vw;
      }
      
      .my-thumbnail{
        width: 8vw;
        height:8vw;
        margin: 0px 10px -5px 0px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-color: white
      }
      .transparent {
          border: 2px solid #192a56;
          background: white;
        }
        .transparent span{
          color: #192a56 !important;
          
      }
      
      .grey{
        background: #8B94AA;
      }
      
      .grey1{
        background: #525F80;
      }
      .grey2{
        background: #192a56;
      }
     
          








      










      
      
   @media (max-width:1000px){
     .circle-line,.verticle-circle,.ai-circle-line,.bc-circle-line{
       display: none;
     }
     .horizontal-circle{
       display: flex;
     }
   }
     
    
  
    
   @media (max-width:768px){
       .Artificial-Intelligence{
           font-size:7vw;
       }
       .Rectangle-67 {
          height: 50vh;
          background-color: #192a56;
        }
        .SliderData{
            /* padding:30vh 0; */
        }
       .line{
           font-size: 2.8vw;
       }
       .line2{
          font-size: 3vw;
          width: 70vw;
      }
      .Rectangle-68{
          height: 28vw;
          width: 100%;
          margin-bottom:20px;
      }
      .quarter{
          font-size: 2.8vw;
      }
      .para{
          font-size:1.8vw;
      }
      .box-container{
        width: 80vw;
        
       
      }
      
      .my-thumbnail{
        width: 100px;
        height:100px;
      }
      .pack-left,.pack-right{
        display: block;
      }
   }
   @media (max-width:575px){
       .Rectangle-68{
           width: 100%;
            padding: 150px 0px;       
       }
       .horizontal-circle{
         display: flex;
       }
       .hidden{
         display: none;
        
       }
       .quarter{
         font-size: 5vw;
       }
       .para{
         font-size: 3.5vw;
       }
   }
/* *{
    transition: 2s;
} */

.navbar{
    width: 100%;
  background-image: -webkit-linear-gradient(right, rgb(8, 56, 61), rgb(1, 100, 95));
  background-image: linear-gradient(to left, rgb(8, 56, 61), rgb(1, 100, 95));
  position: fixed;
  z-index: 111112;
  top: 0;
  box-shadow: 0px 0px 9px #000000a6; 
}

.MuiModal-root-15{
    z-index: 111113 !important;
}

a{
    text-decoration: none !important;
}
.navbar_cont{
    display: flex;
    /* flex-direction: row !important; */
    align-items: center;
    width: 100%;
    padding-top: 10px;

}
/* .drop1{
    position: absolute;
    padding: 20px;

} */



.drop1ul li{
    line-height: 1.19 !important;
    border-bottom: 0.1px solid #00d09d !important;
    padding-left: 0 !important;
    /* padding-right: 500px !important; */
    float: none !important;
    padding: 12px !important;
    text-align: left !important;
    /* background-color: #00d09d; */
    background-image: -webkit-linear-gradient(right, rgb(8, 56, 61), rgb(1, 100, 95));
    background-image: linear-gradient(to left, rgb(8, 56, 61), rgb(1, 100, 95));
}
.navbar-flex{
    color: white;
}
.nav-flex-1 img{
    height: 56px;
    margin-right: 10px;
    margin-top: -5px;

}
.nav-flex-1{
    min-width: 150px;
}
.nav-flex-1 p{
    font-family: Montserrat;
    font-size: 27px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: rgb(255, 255, 255);
}
.nav-flex-2{
    flex:4 1;
}
/* .nav-flex-ul{
    justify-content: flex-end;  
    width: 100%;
} */
.nav-flex-3 ul li:hover{
    cursor: pointer;
    /* border-bottom: 5px solid #00b990; */
    color : #00ce9f;
}
.nav-flex-3 ul li{
    line-height: 30px;
    /* padding-bottom: 25px; */
    white-space: nowrap;
    border-bottom: 5px solid transparent;
    /* float: left; */
    padding: 0px 30px 15px 30px;
    /* margin-top: -5px; */
    /* height: 19px; */
    font-family: myfontRegular;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    /* line-height: 1.19; */
    letter-spacing: normal;
    text-align: center;
    color: rgb(255, 255, 255);
}
.nav-flex-3 ul {
    list-style-type: none;
}


/* .nav-flex-3 ul li:hover{
    color : #00ce9f
} */
.logo-width{
    width: 200px
}

.nav-flex-3 p{
       /* flex: 1; */
        /* flex: 1 1; */
        width: 166px;
        /* height: 19px; */
        font-family: Montserrat;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: center; 
        color: rgb(0, 206, 159);
        /* padding: 0px 20px 0px 20px; */
}
.nav-flex-4{
    flex: 1 1;
}
.nav-flex-4 p{
    /* flex: 1; */
    width: 103px;
    /* height: 19px; */
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: rgb(255, 255, 255);
}
.nav-flex-5 p{
    width: 50px;
    /* height: 19px; */
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: rgb(255, 255, 255);
}
.nav-flex-6 p{
    width: 46px;
    /* height: 19px; */
    font-family: Montserrat;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: rgb(255, 255, 255);
  }
.nav-flex-7 p{
    width: 64px;
  /* height: 19px; */
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: rgb(255, 255, 255);
}
.nav-flex-5{
    padding: 0px 20px 0px 20px;
}
.nav-flex-6{
    padding: 0px 20px 0px 20px;
}
.nav-flex-7{
    padding: 0px 20px 0px 20px;
}
@media screen and (max-width: 1149px){

    .nav-flex-1 img{
        height: 30px;
    }
    .Navbar-logo-piaic{
        height: 80px !important;
        margin-right: 10px !important;
        margin-top: -10px !important;
    }
    body{
        padding-top: 50px;
    }
.navbar_cont{
    flex-direction: column;
}
.nav-flex-3{
    width: 100%;
}

.nav-flex-3 ul li{
    text-align: left;
    width: 100%;
    padding: 10px;
    /* border: 1px solid white; */
    border-bottom: 1px solid #ffffff30;
}
.nav-flex-3 ul {
    list-style-type: none;
    padding: 0px;
    padding-top: 15px;
}
.nav-flex-1{
    width: 100%;
}
.navbar-flex-height_35{
    height: 35px;
}
.navbar-flex-height_auto{
    height: auto;
}
.nav-flex-1 p{
    position: absolute;
    margin-top: -5px;
}
.hamb-btn{
    float: right;
    display: block;
}
#nav-flex-ul{
    display: none;
}
.Navbar-sidemenuicon-computer-li{
    display: none;
}
.nav-flex-3 ul li {
    line-height: 30px;
}
.hamb-btn{
    display: block;
}


}
@media screen and (min-width: 1150px){
    .nav-flex-1 img {
        height: 120px;
        margin-right: 10px;
        margin-top: 60px;
    }
    /* .navbar_cont{
        flex-direction: row !important;
    } */
    .nav-flex-3 ul li{
        float: left ;
    }
    #nav-flex-ul {
        display: inline !important;
    }
    .navbar{
        height: 65px;
    }
    .navbar_cont{
        height: 65px;
    }
    /* #nav-flex-ul{
        display: block;
    } */
    .hamb-btn{
        display: none;
    }
}


#about img{
    height: 12px;
    margin-left: 3px;
}
#about:hover .drop1ul{
    display: block;
}
#about:hover .drop1ul li:hover{
   color : #00ce9f;
}

.drop1ul {

    display: none;
    position: absolute;
    /* display: none; */
    padding-top: 5px;
    /* width: 50px; */
    padding-left: 0px;
    /* margin-left: 10px; */
    /* background-color: #00d09d;
    border: 1px solid #00d09d; */
}
@media screen and (max-width: 1150px){

    .drop1ul {
        position: unset;
        border: none;
    }
    .drop1ul li{
        border-bottom: none !important;
        background-color: #00d09d3b;
        margin-top: 0px !important;
    }
    

}

.active-color{
    /* color : #00ce9f!important; */
    border-bottom: 5px solid #00b990 !important;
}

.Navbar-sidemenuicon-computer{
    height: 40px;
    margin-top: -7px;
    cursor: pointer;
}



    
.bg-modal {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111113;
}

.modal-contents {
  height: 90%;
  width: 90%;
  background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  overflow: scroll;
}


.close {
    float: none;
  font-size: 42px;
  color: white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  
}

.cardArea{
    /* box-shadow: 0px 0px 40px -15px lightgrey; */
    width: 250px;
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }
  .cardArea h1{
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    color: #33322d;
  }
  
  .cardArea p{
    font-family: Montserrat;
      font-size: 12px;
      color: #33322db5;
  }

  .modal-contents::-webkit-scrollbar {
    display: none;
    }
.modalBtn2 {
    width: 120px;
    font-size: 12px;
    height: 40px;
    border-radius: 100px;
    background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
    color: white;
    border: none;
    outline-style: none;
  }

  .modalPCont p{
      max-width: 1000px;
      width: 80%;
  }
  .modalPCont {
      width: 100%;
}

.closeBtnBox{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    color: #333;
    cursor: pointer;
    background-color: #08d88d;
}
.copyright-cont{
    width: 100%;
    height: 70px;
    background-color: #12232f;
    color: #d6d6d6;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo{
    position: absolute;
    right:0;
}
.footer-logo img{
    width: 80px;
}
.footer-logo b{
    margin:0px 5.5px;
}
.main-container-my{
    position: fixed;
    top: 0;
    bottom:0;
    z-index:111112;
    padding: 0;
    width:100vw;
    height:100vw;
    background: white;
    padding:50px;
}
.Not-Found{
    width:80%;
    height:100vw;
    position: absolute;
    left: 50%;
    top:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    margin: auto;
}
.search-image{
    width:25vw;
    height:25vw;
}
.We-are-sorry {
    font-size: 5vw;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
  color: #01796986;
    
  }
  .The-page-you-are-looking-for-is-not-found{
    font-size: 1.5vw;
    position: relative;
    left: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
  color: #01796986;
    
  }
  .div-container{
      display: flex;
    padding: 40px 20px;
    
    
}
.l-2{
    padding-top:40px;
    text-align: right;
}
.left-container{
    width: 100vw;
  }

.right-container img{
    display: block;
    
}

.not{
    font-size: 8vw;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #01796986;
 
}
.not-p{
    font-size: 4vw;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  position: relative;
  left: 5.5vw;
  top: -3vw;
  color: #01796986;
}
.Rectangle-198 {
   padding: 1.2vw 5vw;
    margin:0px 10vw;
    border-radius: 100px;
    color: #00ce9f;
    border: 1px solid #00ce9f;
    background: white;
    -webkit-transition: all 1s;
    transition: all 1s;

    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  }
  .Rectangle-198:hover{
      color: white;
    background: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background: linear-gradient(to left, #08d88d, #0eb5a3);
  }
  .Rectangle-198:hover a{
   
    color: white;
  background: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
  background: linear-gradient(to left, #08d88d, #0eb5a3);
}
  .Rectangle-198 a{
    color: #00ce9f;

  }
  .hidden-show{
      display: none;
  }
  @media(max-width:575px){
    .main-container-my{
        padding: 0;
    }
  
      .div-container{
          display: block;
        position: relative;
        left: -20px;

      }
      .hidden-show{
          display: block;
      }
      .right-container{
        display: none;
    }
    .We-are-sorry{
        text-align: center;
        font-size: 8vw;

    }
    .The-page-you-are-looking-for-is-not-found{
        left: 0;
        font-size: 2.5vw;
        text-align: center;
        display: block;
        margin: 0px auto;
    }
    .search-image{
        width: 35vw;
        height: 35vw;
        display: block;
        margin: auto;

    }
    .l-2{
        text-align: center;
    }
    .not{
        font-size: 10vw;
    }
    .not-p{
        left: 0;
    }
  }

.mcCont{
  max-width: 800px !important;
  width: 90%;
}

.greenCir{
  position: absolute;
  width: 144px;
  height: 144px;
  margin: 3px;
  border-radius: 50%;
  background-color: white;
}
.whiteCir:hover{
  cursor: pointer;
  background-color: #00d09d82;
  background-blend-mode: overlay;
  -webkit-transition: .5s;
  transition: .5s;
}
.whiteCir2{
  width: 136px;
  height: 136px;
  margin: 7px;
  border-radius: 50%;
  position: absolute;
  /* background-color: purple; */
  background-size: cover;
  background-position: center;
}
.whiteCir{
  width: 136px;
  height: 136px;
  margin: 7px;
  border-radius: 50%;
  position: absolute;
  /* background-color: purple; */
  background-size: cover;
  background-position: center;
}
.imgDiv{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #00cf9e;
}
.imgHead{
  width: 150px;
  height: 150px;
  margin-left: 50px;
  margin-bottom: 30px;
}

.cardBody{
  box-shadow: 0px 0px 40px -15px lightgrey;
  width: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.cardBody h1{
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
  color: #33322d;
}

.cardBody p{
  font-family: Montserrat;
    font-size: 12px;
    color: #33322db5;
}
.spans{
  margin-top: 20px;
  margin-bottom: 10px;
}
.spans ul{
  margin-left: -2.5em;
  /* margin: 0px; */
  list-style-type: none;
}
.spans ul li{
  float: left;
}

.span{
  width: 25px;
  height: 25px;
  margin: 2px;
  border-radius: 50%;
}
.span1{
  background-color: #1a65b1;
}
.span2{
  background-color: #22d0fa;;
}
.span3{
  background-color: #521472;
}
.cardBody button {
  width: 120px;
  font-size: 12px;
  height: 40px;
  border-radius: 100px;
  background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
  background-image: linear-gradient(to left, #08d88d, #0eb5a3);
  color: white;
  border: none;
  outline-style: none;
}

.mcHeading{
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #017969;
  margin-top: 80px;
  margin-bottom: 30px;
}



.checking{
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
margin-top: 58%;
}
.whiteCir:hover .checking{
  visibility: visible;
  opacity: 1;
}

/* .checking ul li{
  float: left;
} */

@media (max-width: 700px) {
  


}
@media (max-width: 500px) {
  
  .mcCont{
    width: 100%;
  }

}
.supporterIcons ul{
    list-style-type: none;
}
.supporterIcons ul li{
    float: left;
}
.supporterIcons ul li img{
    height: 4.5vw;
    /* height: 60px; */
    padding: 1vw 2vw;
}

.supportingCont{
    font-family: Montserrat;
    /* font-size: 2vw; */
    font-size: 1.7em;
    font-weight: 600;
    /* text-align: left; */
    color: #017969;
}
.supportingBg{
    /* background-color: #f9f9f9; */
}

.SIimg1{
    margin-left: -35px;
}

@media (min-width: 1600px) {
  
    .supporterIcons ul li img{
        height: 2.5em;
        /* height: 60px; */
    }
   
    
}

@media (max-width: 1000px) {
  
    .supporterIcons ul li img{
        height: 6vw;
        /* height: 60px; */
    }
    .supportingCont{
        font-size: 1.5em;
    }
    
}

@media (max-width: 600px) {
  

    .supporterIcons ul li img{
        height: 7vw;
        /* height: 60px; */
    } .supportingCont{
        font-size: 1em;
    }
    
}

@media (max-width: 500px) {
  

    
}
@media (min-width: 1000px) {
    .management ul li img{
        height: 2.5em !important;
        /* height: 60px; */
        /* padding: 1vw 2vw; */
    }
  }
.bg-modal {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111113;
}

.modal-contents {
  height: 90%;
  width: 90%;
  background-color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  overflow: scroll;
}


.close {
    float: none;
  font-size: 42px;
  color: white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  
}

.cardArea{
    /* box-shadow: 0px 0px 40px -15px lightgrey; */
    width: 250px;
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }
  .cardArea h1{
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    color: #33322d;
  }
  
  .cardArea p{
    font-family: Montserrat;
      font-size: 12px;
      color: #33322db5;
  }

  .modal-contents::-webkit-scrollbar {
    display: none;
    }
.modalBtn2 {
    width: 120px;
    font-size: 12px;
    height: 40px;
    border-radius: 100px;
    background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
    color: white;
    border: none;
    outline-style: none;
  }

  .modalPCont p{
      max-width: 1000px;
      width: 80%;
  }
  .modalPCont {
      width: 100%;
}

.closeBtnBox{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    color: #333;
    cursor: pointer;
    background-color: #08d88d;
}
.cardBody2{
    width: 570px;
    height: 340px;
    padding: 0px;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 3px solid #00ce9f;
    border-bottom: 3px solid #00ce9f;
    min-width: 570px;
    max-width: 570px;
}
.status{
    text-align: center;
    margin-top: 30px;
    margin-bottom: -12px;
    margin-left: 50px;
    margin-right: 50px;
    color: red;
    font-weight: 400;
    font-size: 1.2em
}
.statusRed{
    text-align: center;
    margin-top: 30px;
    margin-bottom: -12px;
    margin-left: 50px;
    margin-right: 50px;
    color: red;
    font-weight: 400;
    font-size: 1.2em
}
.cardBody2 h1{
    display: inline-block;
    /* width: 50px; */
    font-size: 24px;
    margin-left: 10%;
    color: #017969;
    font-weight: 900;
}
.id-card-2-h1{
    margin-left: 15% !important;
}
.logoImage{
    height: 103px;
    margin: 15px 40px 0px 25px;
}
.idCardLocation a{
    color: black;
    text-decoration: underline !important;
}
.logoForm b{
    color: #017969;
}
.logoForm{
    margin-top: 15%;
}
.logoForm p{
    color: black; 
    margin-top: -3%;
    font-size: 1.1rem;
    font-weight: bolder;
}

.cardImage{
    margin: 5%;
    margin-left: 10%;
    border: 1px solid #00ce9f;
}


.UserAuthCard b{
    color: #017969;
}
.UserAuthCard{
    margin-top: 15%;
}
.UserAuthCard p{
    color: black;
    /* margin-top: -3%; */
    font-weight: bolder;
    font-size: 1.1rem;
    margin-top: 67%;
    /* padding-top: 65%; */
    width: 130%;
    padding-top: 4px;
    border-top: 1px solid;
    /* border: 1px solid; */
    margin-left: -35%;
    padding-left: 10%;
}


@media screen and (max-width: 650px){
    /* .copyright{
        display: none;
    } */
}
/* Loader */
.LoaderContainer{
    width:100%;
    height:100vh;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:1111111;
    background: rgba(255, 241, 241, 0.534)
  }
  .loader{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%)
  }

  .lds-resend{
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-resend div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    background: #017969;
    -webkit-animation: lds-resend 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
            animation: lds-resend 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-resend div:nth-child(1) {
    left: 6px;
    -webkit-animation-delay: -0.24s;
            animation-delay: -0.24s;
  }
  .lds-resend div:nth-child(2) {
    left: 26px;
    -webkit-animation-delay: -0.12s;
            animation-delay: -0.12s;
  }
  .lds-resend div:nth-child(3) {
    left: 45px;
    -webkit-animation-delay: 0;
            animation-delay: 0;
  }
  @-webkit-keyframes lds-resend {
    0% {
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }
  @keyframes lds-resend {
    0% {
      top: 6px;
      height: 51px;
    }
    50%, 100% {
      top: 19px;
      height: 26px;
    }
  }
  
  

  @media print {
    .hideOnPrint{
        display: none;
    }

}  
.hiw-box2 .countButton, .hiw-box1 .countButton, .hiw-box3 .countButton, .hiw-box5 .countButton{
    background-color: #00cf9e;
    color: white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
}
.hiw-box4 .countButton, .hiw-box6 .countButton{
    background-color: white;
    color: #00cf9e;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
}
.hiw-box2{
    background-image: -webkit-linear-gradient(left, #e6dada, #dfedf0);
    background-image: linear-gradient(to right, #e6dada, #dfedf0);
    box-shadow: 1px 3px 20px 0 rgba(0, 0, 0, 0.16);
}
.hiw-box4, .hiw-box6{
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
  background-image: linear-gradient(to left, #08d88d, #0eb5a3);
}

.hiw-box1, .hiw-box2{
    padding-top: 20px;
    /* padding-bottom: 20px; */
}
.hiw-box2{
    padding-bottom: 20px;
}
.hiw-box1{
    background-color: #33322d;
}
.hiw-box1-1{
    padding-bottom: 30px;
    background-color: #33322d;
}
.hiw-box3, .hiw-box4, .hiw-box5, .hiw-box6,.hiw-box7{
    padding-top: 50px;
    padding-bottom: 50px;
}
.left-text hr{
   margin-bottom: 20px;
}


.hiw-box2 .countButton, .hiw-box7 .countButton{
    background-color: #00cf9e;
    color: white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
}
.hiw-box3 .para1, .hiw-box5 .para1{
    width: 100%;
    font-size: 13px;
    font-style: normal;
    color: #017969;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 1.7;
}
 .hiw-box4 .para1, .hiw-box6 .para1, .hiw-box7 .para1{
    width: 100%;
    font-size: 13px;
    font-style: normal;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 1.7;
 }
 .hiw-box1 .para1{
    width: 100%;
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
 }
 .hiw-box7{
     background-color: #33322d;
 }
 
 .hiw-box2 .para1{
    width: 100%;
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    color: #017969;
    margin-top: auto;
    margin-bottom: auto;
}
.pakMap{
    height: 250px;
}
.first-col-count{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
}
.hiw-cont{
    /* min-width: 550px; */
}

@media screen and (max-width: 590px){
    .hiw-table-box{
        width: 300px;
    }
    .second-col-area p{
        width: 100px !important;
    }
    .third-col-status p{
        width: 100% !important;
    }
}
.first-col-count p{
    width: 50px;
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    color: #017969;
    margin-top: auto;
    margin-bottom: auto;
}
.second-col-area{
    /* width: 100%; */
}
.third-col-status{
    /* width: 100%; */
}
.hiw-dot-active{
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid #00cf9e;
    background-color: #00cf9e;
    border-radius: 50%;
    margin-left: 15px;
}
.hiw-dot{
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid #00cf9e;
    /* background-color: #00cf9e; */
    border-radius: 50%;
    margin-left: 10px;
}
.col-area-active{
    width: 200px;
    font-size: 13px;
    font-weight: 600;
    background-color: #00d09d !important;
    color: white !important;
    padding: 7px;
    margin: auto;
}
.second-col-area p{
    width: 200px;
    font-size: 13px;
    font-weight: 600;
    color: #017969;
    background-color: white;
    padding: 7px;
    margin: auto;
}
.second-col-area-head{
    width: 200px;
    font-size: 13px;
    font-weight: 600;
    color: white;
    /* background-color: white; */
    padding: 7px;
    margin: auto;
}
.third-col-status p{
    width: 200px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    padding: 7px;
    margin: auto;
}

.hiw-box2-text2{
    /* font-size: 12px; */
    margin-top: 20px;
}
.hiw-box2-text2 p{
    font-size: 13px;
    font-weight: bolder;
    margin-top: 4px;
    margin-bottom: auto;
}

.hiw-box1 h1{
    border-bottom: 1px solid #00d19c;
    /* width: 18%; */
    margin-bottom: 40px;
    text-align: center;
    /* width: 255px; */
    /* height: 44px; */
    font-family: Montserrat;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.92;
    letter-spacing: normal;
    color: #ffffff;
}

.hiw-link:active,
.hiw-link:hover,
.hiw-link:visited
{
    color: #017969;
}


#myForm {
  padding: 5% 0;
}

.Rectangle-58 {
  height: 100%;
  background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  display: flex;
  justify-content: center;
}

.APPLICATION-FORM {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0%;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #017969;
}
.rollNo{
  font-size: 7
}
.label {
  font-size: 18px;
  margin: 3% 0;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #017969;
}

.ContactClass {
  width:100%;
  background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  display: flex;
  justify-content: center;
}

.row2mail {
  margin-top: 20px;
}

.textArea {
  resize: none;
}


.Rectangle-112 {
  height: 50px;
  border-radius: 5px;
  background-color: #00ce9f;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.btnRow {
  margin-top: 20px;
  justify-content: center;
}
.input11::-webkit-input-placeholder,.textArea::-webkit-input-placeholder{
color: #0000003d;
}
.input11::-ms-input-placeholder,.textArea::-ms-input-placeholder{
color: #0000003d;
}
.input11::placeholder,.textArea::placeholder{
color: #0000003d;
}
.errorContact {
  color: rgb(172, 15, 15);
  position: relative;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: -20px;
  text-align: justify;
  width: 552px;
  padding: 5px;
  -webkit-transition: all ease-in-out 2s;
  transition: all ease-in-out 2s;
  width: inherit;
}

.errorElem {
  border: 1px solid rgba(172, 15, 15, 0.589) !important;
}

.input11 {
  height: 50px;
}

.staric {
  font-size: 20px;
}
.calculate-heigth{
  background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  height : calc(100vh - 110px);
  display : flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.calculate-heigth h1{
  color: #017969;
  font-size: 3.4em;
}
.calculate-heigth h2{
  color : #05a08b;
  font-size : 1.8em;
  text-align: center
}

.main{
  display: flex;
  justify-content: center;
}

.beta{
  width: 40vw;
}

@media(max-width:1024px){
  .beta{
    width: 100%;
  }
}
.failed{
  color: rgb(199, 15, 15);

}
.iot {
    color: #252525;
    background: #1b8f8b;
}
.iot-line{
    background: #1b8f8b;
    height: 93% !important
}
.it-1-t{
    border: 2px solid #1b8f8b;
    color:#252525 
}

.it-2-t{
    border: 2px solid #1b8f8b;
    background: #21b8b3;    
}
.it-2-t span{
    color:white !important 
}
.it-3-t{
    border: 2px solid #1b8f8b;
    background: #1ea7a2;    
    color:#252525 
}
.it-3-t span{
    color:white !important 
}
.it-4-t{
    border: 2px solid #1b8f8b;
    background: #1b8f8bfb;    
    color:#252525 
}
.it-4-t span{
    color:white !important 
}
.it-5-t{
    border: 2px solid #1b8f8b;
    background: #0f8b87;    
    color:#252525 
}
.it-5-t span{
    color:white !important 
}
.it-6-t{
    border: 2px solid #1b8f8b;
    background: #0f8b87;    
    color:#252525 
}
.it-6-t span{
    color:white !important 
}



.i1{
    color: #1b8f8b;
    background: white;
}
.i2{
    color: #252525ce;
    background: #1b8f8b20;    
}
.i3{
    color: #252525ce;
    background: #1b8f8b40;    
}
.i4{
    color: #252525ce;    
    background: #1b8f8b60;    
}
.i5{
    background: #1b8f8b80;    
        color: #252525ce;    
}
.i6{
    color: #252525ce;    
    background: #22a19d;    
}
.bc line,.bc .heading{
    color: #252525;

}



.bc .Artificial-Intelligence,.bc .line{
    color: #252525;
}
.bc+.line2{
    color: #252525;

}
.b1 .quarter{
    color: #252525;     
}
.b1 .para{
    color: #252525;  

}
.b2 {
    background:rgba(251, 195, 20,0.5);
    
    color: #252525;   
}
.b3 {
    background:rgba(251, 195, 20,0.75);
    color: #252525;  
}
.b4 {
    background:#fbc314;
    color: #252525;  
}

.b-1-t{
    border:2px solid #fbc314 !important;
    color: #252525 !important;
}
.b-2-t{
    background:  #FCE088  !important;
}

.b-3-t{
    background:  #FCD24E !important;
}


.cl-cr{
    background: #E83F8F !important;
}


.label-alignment {
    text-align: center;
}
.react-photo-gallery--gallery{
    /* background-image: linear-gradient(to bottom, #dfedf0, #e6dada); */
    border-radius: 5px;
}
.react-photo-gallery--gallery img{
    padding: 1px;
    height: auto;
    border: 1px solid rgba(51, 52, 53, 0.226);
    object-fit:cover; 
}
.react-photo-gallery--gallery img:hover{
    opacity: 0.5;
    filter: alpha(opacity=50); 
    -webkit-transform: scale(0.8); 
            transform: scale(0.8);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.gallery-heading{
    font-size: 4vw;
    /* letter-spacing: 2px !important; */
    /*text-shadow: 2px 0px 5px #423f3f;*/
    color: rgb(63, 66, 71) !important;
    text-transform: uppercase;
    word-spacing: 2vw;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #423f3f;
        text-align: center;
        margin: 20px;
}
.gallery-para{
    text-align: center;
    letter-spacing: 1vw;
    line-height: -10px;
    width: 80%;
    margin: auto;
    border-top: 1.5px solid rgba(0, 0, 0, 0.548);
    /*text-shadow: 1px 0px 2px #423f3f;*/
    padding-top:10px;
    margin-bottom: 20px;
    font-weight: bold;
}
div.women-background{
    width: 100vw;
    position: relative;
    height: 40vw !important;
    background-position: center 10px;
    background-size: 120%;
    background-repeat: no-repeat;
    background-color: #ddd;
}
.women-paragraph{
    padding: 100px;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:16px;
    font-weight: bolder;
    color: #46464b81;
}
.women-paragraph img{
    width: 300px;
    
}
.gallery-container{
    position: relative;
    padding: 0px 10px;
    margin: 20px auto;
}


@media (max-width:980px){
    div.women-background{
        height: 50vw !important;
        background-size: 150%;
    }
.women-paragraph{
    padding: 50px;
    font-size: 14px;
}
.women-paragraph img{
    width: 250px;
}
}

@media (max-width:768px){
    div.women-background{
        height: 70vw !important;
        background-size: 200%;
    }
.women-paragraph{
    font-size: 10px;
    padding: 20px;
}
.women-paragraph img{
    width: 200px;
}
}
@media (max-width:768px){
    .women-paragraph{
        flex-direction: column;
        font-size: 12px;
        margin: 20px auto;
    }
}

