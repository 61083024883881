.copyright-cont{
    width: 100%;
    height: 70px;
    background-color: #12232f;
    color: #d6d6d6;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logo{
    position: absolute;
    right:0;
}
.footer-logo img{
    width: 80px;
}
.footer-logo b{
    margin:0px 5.5px;
}