.PresidentLaunchingWeb-bg{
  background-color: #00bf9a;
  /* height: 500px; */
  padding-bottom: 100px;
}

.PresidentLaunchingWeb-content-section{
  width: 99%;
  /* height: 400px; */
  /* background-color: blue; */
}
@media (min-width: 400px){
  .PresidentLaunchingWeb-content-section{
    /* width: 98%; */
    /* height: 400px; */
    /* background-color: blue; */
  }
}
@media (max-width: 1600px){
.PresidentLaunchingWeb-container{
  padding-left: 4%;
  padding-right: 4%;
}
}

.PresidentLaunchingWeb-image{
  width: 100%;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.slick-track{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.PresidentLaunchingWeb-row{
  /* height: 400px; */
}

.PresidentLaunchingWeb-text h3{
  /* width: 238.5px;
  height: 24.5px; */
  font-family: myfontBold;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: -0.8px;
  text-align: left;
  color: #2b2929;
}

.PresidentLaunchingWeb-text p{
  font-family: myfontLight;
  font-size: 12px;
  letter-spacing: 0.6px;
  text-align: justify;
  color: #ffffff;
}
.PresidentLaunchingWeb-content-heading{
  /* padding-left: 1%; */
  font-size: 42px;
  text-align: left;
  color: #ffffff;
  font-family: myfontBold;
  padding-bottom: 20px;
  padding-top: 50px;
}

@media (min-width: 1000px){
  .PresidentLaunchingWeb-text p{
    font-size: 15px;
  }
  .PresidentLaunchingWeb-text h3{
    font-size: 27px;
  }
}

.slick-dots{
  bottom: -40px !important;
}
.slick-dots li button:before{
  font-size: 12px !important;
     opacity: 1; 
    color: white !important;
}
.slick-dots li{
  margin: 0px -1px !important;
}
