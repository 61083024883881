.react-photo-gallery--gallery{
    /* background-image: linear-gradient(to bottom, #dfedf0, #e6dada); */
    border-radius: 5px;
}
.react-photo-gallery--gallery img{
    padding: 1px;
    height: auto;
    border: 1px solid rgba(51, 52, 53, 0.226);
    object-fit:cover; 
}
.react-photo-gallery--gallery img:hover{
    opacity: 0.5;
    filter: alpha(opacity=50); 
    transform: scale(0.8);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.gallery-heading{
    font-size: 4vw;
    /* letter-spacing: 2px !important; */
    /*text-shadow: 2px 0px 5px #423f3f;*/
    color: rgb(63, 66, 71) !important;
    text-transform: uppercase;
    word-spacing: 2vw;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #423f3f;
        text-align: center;
        margin: 20px;
}
.gallery-para{
    text-align: center;
    letter-spacing: 1vw;
    line-height: -10px;
    width: 80%;
    margin: auto;
    border-top: 1.5px solid rgba(0, 0, 0, 0.548);
    /*text-shadow: 1px 0px 2px #423f3f;*/
    padding-top:10px;
    margin-bottom: 20px;
    font-weight: bold;
}
div.women-background{
    width: 100vw;
    position: relative;
    height: 40vw !important;
    background-position: center 10px;
    background-size: 120%;
    background-repeat: no-repeat;
    background-color: #ddd;
}
.women-paragraph{
    padding: 100px;
    text-align: justify;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:16px;
    font-weight: bolder;
    color: #46464b81;
}
.women-paragraph img{
    width: 300px;
    
}
.gallery-container{
    position: relative;
    padding: 0px 10px;
    margin: 20px auto;
}


@media (max-width:980px){
    div.women-background{
        height: 50vw !important;
        background-size: 150%;
    }
.women-paragraph{
    padding: 50px;
    font-size: 14px;
}
.women-paragraph img{
    width: 250px;
}
}

@media (max-width:768px){
    div.women-background{
        height: 70vw !important;
        background-size: 200%;
    }
.women-paragraph{
    font-size: 10px;
    padding: 20px;
}
.women-paragraph img{
    width: 200px;
}
}
@media (max-width:768px){
    .women-paragraph{
        flex-direction: column;
        font-size: 12px;
        margin: 20px auto;
    }
}
