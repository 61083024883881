
/* *{
  transition: 1s;
} */

.AvailPrograms{
  margin-top: -160px;
  background-color: transparent;
  /* height: 600px; */
  padding-bottom: 30px;
}
@media screen and (max-width: 1250px){
  .AvailPrograms{
    margin-top: -100px;
  }
}
.AvailPrograms-heading{
  text-align: right;
  font-family: myfontBold;
  color: #666666;
}

@media screen and (max-width: 950px) and (min-width: 768px){
  .avail-pro-con{
    padding: 5px !important;
  }

  .new-program-title{
    font-size: 1.5vw;
  }
}
@media screen and (max-width: 950px){
  .AvailPrograms{
    margin-top: 0px;
  }
  
  .AvailPrograms-heading{
    text-align: center;
  }
}

@media screen and (min-width: 1500px){
  .AvailPrograms-heading{

    letter-spacing: 18px;
    font-size: 28px;
  }
}
@media screen and (max-width: 1499px){
  .AvailPrograms-heading{
    letter-spacing: 1.2vw; 
    font-size: 2.1vW;
  }
}
.AvailProgram-programbox-section{
  padding-left: 4%;
  padding-right: 4%;
}
.AvailPrograms-flex-box{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  max-height: 390px;
  width: 30vw;
  height: 30vw;
  border-radius: 5px;
}

/* .AvailProgram-programbox-cont-hover-section{
  margin-top: -74%;
  width: 100%;
  height: 100%;
} */

.AvailProgram-programbox-cont{
  min-width: 225px;
  width: 95%;
  height: 190px;
  border-radius: 7.5px;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: transparent;
  overflow: hidden;
}

.AvailProgram-programbox-cont-title{
  background-color: #fdfdfd;
}
@media screen and (max-width: 990px){
  .AvailProgram-programbox-cont{
    min-width: 230px;
    max-width: 400px;
  }

}
@media screen and (max-width: 850px){
  .AvailProgram-programbox-cont{
    height: 150px;
    font-size: 13px;
  }
  .AvailPrograms-heading {
    letter-spacing: 13px;
    font-size: 20px;
}
}
@media screen and (max-width: 550px){
  
  .AvailPrograms-heading {
    letter-spacing: 2.2vw;
    font-size: 3.5vw;
}
}

.AvailProgram-programbox-cont-ii .AvailProgram-programbox-cont-header{
  background-color: #192a56;
}
.AvailProgram-programbox-cont-ii{
  color: #192a56;
}

.AvailProgram-programbox-cont-cn .AvailProgram-programbox-cont-header{
  background-color: #e1006a;
}
.AvailProgram-programbox-cont-cn{
  color: #e1006a;
}

.AvailProgram-programbox-cont-bc .AvailProgram-programbox-cont-header{
  background-color: #fbc314;
}
.AvailProgram-programbox-cont-bc{
  color: #fbc314;
}


.AvailPrograms-programsInDev-heading{
    text-align: left;
    font-family: myfontBold;
    letter-spacing: 7px;
    font-size: 26px;
    color: #666666;
}
@media screen and (max-width: 650px){
  .AvailPrograms-programsInDev-heading{
    letter-spacing: 5px;
    font-size: 22px;
}
}
@media screen and (max-width: 455px){
  .AvailPrograms-programsInDev-heading{
    letter-spacing: 2px;
    font-size: 18px;
}
}
.AvailPrograms-programsInDev-section{
  padding-left: 5%;
  padding-top: 30px;
  transition: 2s;
}

.AvailPrograms-programsInDev-content{
  scroll-behavior: smooth;
}
.AvailPrograms-programsInDev{
  width: 100%;
}
.AvailPrograms-programsInDev-content{
 width: auto;
 white-space:nowrap; 
 overflow-y: hidden;
}

.AvailPrograms-programsInDev-content::-webkit-scrollbar{
  display: none;
}

.AvailPrograms-programsInDev-box{
  color: white;
  display: inline-block;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
  background: linear-gradient(to right, #9a9a9a, #2e2e2e);
  /* background: gray; */
  margin-right: 50px;
  width: 300px;
  height: 150px;
  overflow: hidden !important;
  /* padding: 0px 20px; */
  /* transition: all 2s;   */
}
.AvailPrograms-programsInDev-box div{
  transition: all 1s;
  padding: 0px 10px 0px 15px;
}




.innerText{
  font-family: myfontLight;
  letter-spacing: 8px;
  white-space: normal;
}
@media screen and (max-width: 575px){
 
.innerText{
  width: 100%;
  font-size: 14px;
  letter-spacing: 5px;
  white-space: nowrap;
  overflow: hidden;
}
}
/* 
.slick-dots > li:first-child button:before{
  background-color: black;
  height: 20px;
  width: 20px;
  -webkit-clip-path: polygon(100% 8%, 45% 51%, 100% 92%, 86% 100%, 18% 53%, 85% 0);
  clip-path: polygon(100% 8%, 45% 51%, 100% 92%, 86% 100%, 18% 53%, 85% 0);
}

.slick-dots > li:last-child button:before{
  background-color: black;
    height: 20px;
    width: 20px;
    -webkit-clip-path: polygon(12% 0, 74% 51%, 10% 100%, 0 90%, 52% 51%, 0 10%);
    clip-path: polygon(12% 0, 74% 51%, 10% 100%, 0 90%, 52% 51%, 0 10%);
} */
/* 
.slick-dots{
  top: 180px;
} */

.slick-arrow{
  display: none !important;
}


.controls{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controls__item{
  opacity: .2;
  background: 0 0;
  box-shadow: none;
  border: none;
}
.page-indicators {
  display: flex;
}
.controls__item {
  opacity: .2;
  background: 0 0;
  box-shadow: none;
}
.page-indicators__item {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: .2rem;
  opacity: .4;
  background: 0 0;
  border: none;
  box-shadow: none;
}
.page-indicators__item__icon {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #525c65;
}


.new-program-box{
  border-radius: 5px;
  min-height:180px;
  max-width: 350px;
  background: white;
  box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  cursor: pointer;
  margin-bottom:20px;
  width: 100%;
}
.new-program-box::before{
  content: "";
  height:100%;
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  border-radius: 5px;
  transition: all 0.5s;
}
.new-program-box:hover::before{
  transition: all 0.5s;
}
.new-bc-box:hover::before{
  background-color: #fbc314;
}
.new-ai-box:hover::before{
  background-color: #192a56;
}
.new-cn-box:hover::before{
  background-color: #e1006a;
}
.new-iot-box:hover::before{
  background-color: #1b8f8b;
}
.new-hover-data{
  height:100%;
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.new-hover-data strong{
  font-family: myfontBold;
  text-align: left;
  padding-left: 8px;
}
.new-hover-data p{
  font-size:14px;
  text-align: left;
  padding: 10px 10px;
  /* word-break: break-all; */
}
@media screen and (max-width: 1200px){
  .new-hover-data p{
    font-size:12px;
  }
}
.new-program-box:hover .new-upper-bar,.new-program-box:hover .new-program-title{
  opacity: 0;
  transition: all 1s;
}

.new-program-box:hover{
  opacity:0.8;
}

.new-program-box:hover .new-hover-data{
  opacity:1;
  transform:scale(0.8,0.8);
  transition: all ease-in-out 0.5s;
}
.new-hover-data{
  transform:scale(0.5,0.5);
    opacity:0;
  transition: all ease-in-out 0.5s;

  }


.new-upper-bar{
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.new-ai-bar{
  background-color: #192a56;
}
.new-ai-title{
  color: #192a56;
}
.new-cn-bar{
  background-color: #e1006a;
  transition: all 2s;
}
.new-cn-title{
  color: #e1006a;
}
.new-bc-bar{
  background-color: #fbc314;
  transition: all 2s;

}
.new-bc-title{
  color: #fbc314;
}

.new-iot-bar{
  background-color: #1b8f8b;
  transition: all 2s;

}
.new-iot-title{
  color: #1b8f8b;
}
.new-program-title{
  white-space: nowrap;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%,-60%)
}



.AvailPrograms-programsInDev-hr-line{
  width: 100px;
  height: 3px;
  background-color: black;
}


.AvailProgram-programbox-cont-hover-section{
  /* margin-top: -74%; */
  width: 100%;
  height: 100%;
  /* display: none; */
  position: absolute;
  opacity: 0;
  /* background-color: #a52a2a7a; */
}

.AvailProgram-programbox-cont-hover-text{
width: 90%;
max-height: 80%;
/* background-color: purple; */
}

.AvailProgram-programbox-cont-hover-text h4{
    font-family: myfontRegular;
    font-size: 12px;
    text-align: left;
  transform: translateY(-10px);
    color: #ffffff;
  transition: transform 0.5s;

    
}
.AvailProgram-programbox-cont-hover-section:hover h4{
transform: translateY(0px);
  transition: transform 0.5s;
}
.AvailProgram-programbox-cont-hover-text p{
  font-family: myfontLight;
  font-size: 10px;
  text-align: left;
  transform: translateY(10px);
  color: #ffffff;
  transition: transform 0.5s;
}
.AvailProgram-programbox-cont-hover-section:hover p{
  transform: translateY(0px);
    transition: transform 0.5s;
  }
.AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-header, .AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-title,
.AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-header, .AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-title,
.AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-header, .AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-title
{
  /* display: none !important; */
  opacity: 0;
  /* opacity: 4.9; */
}

.AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-hover-section,
.AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-hover-section,
.AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-hover-section
{
  display: flex;
  opacity: 0.8;
}
.AvailProgram-programbox-cont-ii:hover .AvailProgram-programbox-cont-hover-section{
  background-color: #192a56;
}
.AvailProgram-programbox-cont-cn:hover .AvailProgram-programbox-cont-hover-section{
  background-color: #e1006a;
  transition: all 2s;

}
.AvailProgram-programbox-cont-bc:hover .AvailProgram-programbox-cont-hover-section{
  background-color: #fbc314;
  transition: all 2s;

}



.AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST:hover,
.AvailPrograms-programsInDev-box-ENTREPRENEURSHIP:hover,
.AvailPrograms-programsInDev-box-SDN-5G:hover,
.AvailPrograms-programsInDev-box-INTERNET-OF-THINGS:hover,
.AvailPrograms-programsInDev-box-ANDROID:hover,
.AvailPrograms-programsInDev-box-AUGMENTED-REALITY:hover,
.AvailPrograms-programsInDev-box-IOS:hover
{
  /* background-image: none; */
  cursor: pointer;
  transition: 1s;
}

.AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST:hover>div{
  background-color: #01bcd4;
}
.AvailPrograms-programsInDev-box-ENTREPRENEURSHIP:hover>div{
  background-color: #000000;

}
.AvailPrograms-programsInDev-box-SDN-5G:hover>div{
  background-color: #f12742;
}
.AvailPrograms-programsInDev-box-INTERNET-OF-THINGS:hover>div{
  background-color: #1b8f8b;
}
.AvailPrograms-programsInDev-box-ANDROID:hover>div{
  background-color: #a4c639;
}
.AvailPrograms-programsInDev-box-AUGMENTED-REALITY:hover>div{
  background-color: #624d97;
}
.AvailPrograms-programsInDev-box-IOS:hover>div{
  background-color: #696969;
}


/* .AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ENTREPRENEURSHIP,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-SDN-5G,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-INTERNET-OF-THINGS,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ANDROID,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-AUGMENTED-REALITY,
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-IOS
{
  background-image: none;
  cursor: pointer;
  transition: 2s;
}

.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-MOBILE-WEB-SPECIALIST{
  background-color: #01bcd4;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ENTREPRENEURSHIP{
  background-color: #000000;

}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-SDN-5G{
  background-color: #f12742;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-INTERNET-OF-THINGS{
  background-color: #1b8f8b;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-ANDROID{
  background-color: #a4c639;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-AUGMENTED-REALITY{
  background-color: #624d97;
}
.AvailPrograms-programsInDev-section:hover .AvailPrograms-programsInDev-box-IOS{
  background-color: #696969;
} */


