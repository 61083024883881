@import url('https://fonts.googleapis.com/css?family=Montserrat');
::selection {
  color: white; 
  background: #51d98d;
}
html,body{
  /* height:100%;
  width:100%; */
  overflow-x:hidden; 
}
body {
  margin: 0;
  padding: 0;
  /* overflow-x: hidden !important; */
  min-width: 350px;

  
  /* padding-top: 60px; */
}
/* @font-face {
  font-family: 'Open Sans' !important;
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"),local("OpenSans-Semibold"),url('https://fonts.gstatic.com/s/opensans/v13/MTP_ySUJH_bn48VBG8sNSqRDOzjiPcYnFooOUGCOsRk.woff') format("woff")
} */
@font-face{
  font-family: myfontBold;
  src: url("./assets/Fonts/open-sans/OpenSans-Bold.ttf");
}
@font-face{
  font-family: myfontExtraBold;
  src: url("./assets/Fonts/open-sans/OpenSans-ExtraBold.ttf");
}
@font-face{
  font-family: myfontItalic;
  src: url("./assets/Fonts/open-sans/OpenSans-Italic.ttf");
}
@font-face{
  font-family: myfontLight;
  src: url("./assets/Fonts/open-sans/OpenSans-Light.ttf");
}
@font-face{
  font-family: myfontRegular;
  src: url("./assets/Fonts/open-sans/OpenSans-Regular.ttf");
}
*{
  font-family: "myfontRegular",sans-serif;
  
}


/* Loader */
.LoaderContainer{
  width:100%;
  height:100vh;
  position: fixed;
  top:0;
  z-index:1111111;
  background: rgba(255, 241, 241, 0.534)
}
.loader{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #017969;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #017969 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swal2-title{
  color: rgba(255, 255, 255, 0.842) !important;
}
.swal2-content{
  color: rgba(255, 255, 255, 0.842) !important;
}
.swal2-icon.swal2-warning.swal2-animate-warning-icon{
  color: rgba(255, 255, 255, 0.842) !important;
}
.swal2-container{
  background: transparent !important;
}

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)>.swal2-modal{
  background: rgba(0, 0, 0, 0.78);

}
#swal2-content{
  font-size: 15px !important;
}
#alert-div{
  color: rgba(255, 255, 255, 0.842) !important;
}
#alert-div a{
  color: rgb(93, 163, 255);
}






.my-top-alert{
  margin-top:60px !important;
  cursor: pointer !important;
  background: rgba(0, 0, 0, 0.26) !important;
  box-shadow: 0px 0px 10px inset rgba(0,0,0, 0.4);
  transition: all 0.5s;

}
.my-top-alert:hover{
  background: rgba(0, 0, 0, 0.479) !important;
  transition: all 0.5s;

}

.my-top-alert a {
  color: white !important;
}

.my-form-alert{
  margin-top:60px !important;
  cursor: pointer !important;
  max-width: 600px !important;
  /* background: rgba(0, 0, 0, 0.26) !important; */
  background: linear-gradient(to left, rgba(8, 216, 141,1), rgba(14, 181, 164,0.8)) !important;
  box-shadow: 0px 0px 10px inset rgba(0,0,0, 0.4);
  transition: all 0.5s;
  color: white !important;
  /* font-weight: bold; */
  min-height:380px !important; 

}
.my-form-alert:hover{
  /* background: rgba(0, 0, 0, 0.479) !important; */
  transition: all 0.5s;

}
.my-form-alert .iziToast-message{
  font-size: 20px !important;
  line-height: 25px !important;
  text-align: left !important;
  position: relative;
  top: 50px !important;
}
.my-form-alert .iziToast-title{
  margin:10px auto !important;
  font-size: 30px !important;
  display: block !important;
  line-height: 25px !important;
  text-align: center !important;
  /* border: 1px solid red; */
  width: 100% !important;
}
.my-form-alert .iziToast-close{
  height: 50px !important;
}
.my-btn-div{
  position: relative;
  top:-50px !important;
}
.rdt{
  font-weight: bold;
}

.rdt-red {
  font-weight: bold;
  color: rgb(165, 0, 0);
}

.date-check-p{
  max-width:555px;
  margin: auto;
  font-size: 14px;
  text-align: justify;
  word-break: break-all;
}
@media (max-width:768px){
  .my-btn-div{
    top: 0px !important;
  }
  .date-check-p{
    max-width:70vw;
  }
}

@media (max-width:450px){
  .my-form-alert .iziToast-message{
    font-size:15px !important; 
    top: 10px !important;
  }
}