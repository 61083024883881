.PresidentInitiative-cont{
    background-image: linear-gradient(to right, #e6dada , #dfedf0);
}
.president_png{
    width: 90%;
}
.PresidentInitiative-heading-h1{
    font-family: myfontExtraBold;
    font-size: 60px;
    font-weight: bold;
    text-align: left;
    color: #2b2929;
}
.PresidentInitiative-png-bg{
    background-image: url(../../../assets/presidentinitiativebgpng.png);
    background-size: cover;
    background-position: bottom;
}
.PresidentInitiative-heading-h2{
    font-family: myfontRegular;
    font-size: 30px;
    text-align: left;
    color: #2b2929;
}
.PresidentInitiative-heading-p{
    font-family: myfontRegular;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: #2b2929;
    min-height: 15vw;
    margin-top: 50px;
    /* text-align: justify; */
}
.PresidentInitiative-button-apply{
    border: none;
    outline-style: none;
    margin-top: -7px;
    color: white;
    padding: 11px 44px;
    font-size: 12px;
    border-radius: 5px;
    background-image: -webkit-linear-gradient(right, #08d88d, #0eb5a3);
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
}

.PresidentInitiative-button-status:active,
.PresidentInitiative-button-status:hover,
.PresidentInitiative-button-status:visited
{
    color: white;
}
.PresidentInitiative-10k b{
    white-space: nowrap !important;
    font-family: myfontExtraBold;
}
.PresidentInitiative-10k{
    font-size: 35px;
    margin-top: -15px;
}
.PresidentInitiative-AppRec{
    font-size: 8.5px;
    margin-top: -10px;
}
.PresidentInitiative-vr-line{
    display: inline-block;
    width: 2px;
    height: 45px;
    background-color:  #192a56;
}
.PresidentInitiative-table tr td{
    padding: 15px;
}
.PresidentInitiative-headings{
    padding-bottom: 50px;
    width: 85%;
}
@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.1,1.1);
    }

    100% {
        transform: scale(1,1);
    }
}




.single-featured-image-header  {
    animation: zoominoutsinglefeatured 30s forwards;
   
}

.odometer-value{
    font-family: myfontExtraBold;
    margin-top: -2.5px;
    text-align: center;
}
.PresidentInitiative-10k{
    overflow: hidden;
    max-height:43px;
    margin-bottom: 9px;
    width: 90px;

}

.myfade  {
    animation: myfade 1s ease-in-out;
    
}








@media (min-width: 800px) and (max-width: 1200px){
    .PresidentInitiative-heading-h1{
        font-size: 40px;
    }
    .PresidentInitiative-heading-h2{
        font-size: 24px
    }
    .PresidentInitiative-heading-p{
        font-size: 14px;
    }
}
@media (min-width: 400px) and (max-width: 799px){
    .PresidentInitiative-heading-h1{
        font-size: 30px;
    }
    .PresidentInitiative-heading-h2{
        font-size: 20px
    }
    .PresidentInitiative-heading-p{
        font-size: 12px;
    }
}
@media (max-width: 499px){
    .PresidentInitiative-heading-h1{
        font-size: 20px;
    }
    .PresidentInitiative-heading-h2{
        font-size: 15px
    }
    .PresidentInitiative-heading-p{
        font-size: 10px;
    }
}
@media (max-width: 575px){
    .president_png{
        margin-top: 20px;
        width: 30%;
        float: left;
    margin-left: 5%;
    }
}








