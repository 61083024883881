.Rectangle-58 {
    width:100% !important;
    background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
    display: flex;
    justify-content: center;
  }

  .Rectangle-59::placeholder{
    color: #00000063;
  }
  .disable-btn{
    background: lightgray!important;
    cursor: not-allowed!important;
  }
  #myForm{
      padding:5% 0;
  }
  select:focus,input:focus{
     box-shadow: none !important;
  }

  button:focus{
      outline: none;
  }
  .captcha{
    display: flex;
    margin: 30px 0px;
    justify-content: center;
  }
  .additionalData{
    font-size: 11px
  }
  #container-check{
    margin:3% 0px -0.5% 0;

  }

  #dl{
    vertical-align: middle;
    margin: 0px 10px;
  }
  .check-message{
    display: block;
    white-space: pre-wrap;
    width: 550px;
    color: #017969;    
    margin-top:2px !important;
    font-size:10px !important;
  }
  .cityOfStudy-message{
    display: block;
    white-space: pre-wrap;
    width: 550px;
    color: #f80909;    
    margin-top:2px !important;
    font-size:13px !important;
  }
  .regClosed-message{
    display: block;
    text-align: center;
    white-space: pre-wrap;
    width: 550px;
    color: #f80909;    
    margin-top:2px !important;
    font-size:25px !important;
  }

  .regClosed-message-hidden{
    display: none;
    text-align: center;
    white-space: pre-wrap;
    width: 550px;
    color: #f80909;    
    margin-top:2px !important;
    font-size:25px !important;
  }

  /* Customize the label (the container) */
.container-check{
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #039e89;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} 
  
  .my-error{
    display: block;
    text-align: center;
    color: rgb(165, 0, 0);
    margin: auto;
    white-space: pre-wrap;
    width: 550px;
  }
  
  .APPLICATION-FORM {
    font-size: 30px;
    font-weight: 600;
    margin-bottom:10%; 
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: center;
    color: #017969;
  }
 
  .label {
    font-size: 18px;
    margin:3% 0;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #017969;
  }

  .Rectangle-59 {
    width: 553.8px;
    height: 55px;
    font-size: 15px !important;
    border-radius: 5px;
    background-color: #ffffff;
  }
  .radio-label{
      display: inline-block;
  }
  .radioLabel{
    color: #017969;
    cursor: pointer;
  }
  .radio-group{
      display: inline-block;
      margin-left:10%;
  }
  .Ellipse-13 {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
    left: 5px;
  }
  .Rectangle-60 {
    width: 553.8px;
    height: 60px;
    margin: 4% 0px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    background-color: #00ce9f;
  }
  .Rectangle-62 {
    width: 400px;
    height: 60px;
    border: none;
    cursor: pointer;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius:0px;
    color: white;
    background-color: #00ce9f;
  }
  .Rectangle-63 {
    width: 400px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius:0;
    background-color: #ffffff;
    display: inline-block;
  }
  .-File-type-jpg-jpeg-png {
    margin:0 auto;
   padding: 5px 50px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #00ce9f;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background:#fff url(./down.png)  97% 50% no-repeat ;
    cursor: pointer;
  }
  
  [type="date"] {
    background:#fff url(./down.png)  97% 50% no-repeat ;
    cursor: pointer;
  }
  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }
  [type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  .placeholder-headshot {
    width: 158px;
  }
  .img-container{
    display: inline-block;
    float: left;
    height:211px;
    width:150px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #F0F0F0;
    border: 1px solid #F0F0F0;
    border-top-left-radius:4px;
    border-bottom-left-radius: 4px; 
  }
  #swal2-content{
    font-size: 14px;
    font-weight: bold;
    text-align: justify !important;
  }
   .swal2-confirm{
     border-radius: 100px !important;
     background-image: linear-gradient(to left, #08d88d, #0eb5a3) !important;
     border: none !important;
     padding:10px 80px !important;
     outline-style: none !important;
     color: white;
   }
   
   .swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}
   
   
  
    input[type="radio"] {
      display:none;
  }

  input[type="radio"] + span {
      display:inline-block;
      width:15px;
      height:15px;
      margin:-1px 4px 0 4px;
      vertical-align:middle;
      cursor:pointer;
      -moz-border-radius:  50%;
      border-radius:  50%;
  }
  
  input[type="radio"] +  span {
       border: 1px solid #017969;
  }
  
  input[type="radio"]:checked +  span{
       background-color:#039e89;
  }
  
  input[type="radio"] +  span,
  input[type="radio"]:checked +  span {
    -webkit-transition:background-color 0.4s linear;
    -o-transition:background-color 0.4s linear;
    -moz-transition:background-color 0.4s linear;
    transition:background-color 0.4s linear;
  } 






.error{
    color: rgb(172, 15, 15);
    position: relative;
    font-size: 15px;
    margin:10px 0;
    text-align: justify;
    width: 552px;
    padding: 5px;
    transition: all ease-in-out 2s;
}


.errorElem{
    border: 1px solid rgba(172, 15, 15, 0.589) !important;
}

.min-height100vh-120px{
  min-height: calc(100vh - 125px);
}

@media (max-width: 1149px){
.min-height100vh-120px{
  min-height: calc(100vh - 120px);
}
}

@media (max-width:585px){
    .error{
        font-size: 16px;
        width: 90vw !important;
        margin: auto;
    }
    .APPLICATION-FORM{
      font-size: 1.8rem !important;
      margin: 30px auto;
    }
    .cityOfStudy-message{
      width:85vw !important;
      margin: auto;
    }
    .Rectangle-58{
        display: block;
        justify-content: unset;
        width:100vw !important;
        margin: 0;
        padding: 0;
      text-align: center;

        background: linear-gradient(to bottom, #dfedf0, #e6dada);
    }
    .label{
        display:block; 
        width: 90vw !important;
        margin:3% auto !important;
        font-size: 14px !important;
    }
    .Rectangle-59{
        width: 90vw !important;
        margin: auto !important;
        height:50px;
    }
    .Rectangle-78{
      width: 90vw !important;
        margin: auto;        
    }
    .Rectangle-63{
      width: 90vw !important;
        border-radius:5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0; 
        margin: auto; 
    }
    .Rectangle-62{
        width: 100%;
        border-bottom-left-radius: 5px;

    }
  
    .img-container{
        width: 90vw;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; 
    }
    .-File-type-jpg-jpeg-png {
        font-size: 12px;
    }
    .Rectangle-60{
        width: 90vw;
        display: block;
        margin: 20px auto;
    }
    .check-message{
      width: 90vw;
      margin: 20px auto;
      text-align: justify;
    }
}

