.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  padding-top: 55px;
}

@media print {
  body {-webkit-print-color-adjust: exact;}
  #printButton{
    display: none;
  }


  #pdfText{
    margin-top: 10%;
    font-size: 22px;
  }

  .copyright{
    position: fixed;
    bottom: 0;
  }
  }
/* .idCardCont{
  max-width: 1000px !important;
} */



/* width height classes */
.height-10{
  height: 10%;
}
.height-20{
  height: 20%;
}
.height-30{
  height: 30%;
}
.height-40{
  height: 40%;
}
.height-50{
  height: 50%;
}
.height-60{
  height: 60%;
}
.height-70{
  height: 70%;
}
.height-80{
  height: 80%;
}
.height-90{
  height: 90%;
}


.hw{
  width: 100%;
}
.hh{
  height: 100%;
}


.width-10{
  width: 10%;
}
.width-20{
  width: 20%;
}
.width-30{
  width: 30%;
}
.width-40{
  width: 40%;
}
.width-50{
  width: 50%;
}
.width-60{
  width: 60%;
}
.width-70{
  width: 70%;
}
.width-80{
  width: 80%;
}
.width-90{
  width: 90%;
}


.slick-dots li.slick-active button:before{
  opacity: 1 !important;
}
.slick-dots li button:before{
  opacity: .4 !important;
}

/* height width end */