.App {
  text-align: center;
}




.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer{
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 50px;
  padding-bottom: 10px;
  background-color: #202f39;
  color: white;
  text-align: left;
}
.footer ul li{
  cursor: pointer;
  list-style-type: none;
  font-family: myfontLight;
  font-size: 12px;
  color: #ffffffc4;
  padding-bottom: 3px;
  letter-spacing: 0.3px;
}
.footer ul li img{
  height: 20px;
  margin: 2px 15px 4px -35px;
}
.footer ul{
  /* padding: 0px; */
}

.footer-list-heads{
  /* font-family: Montserrat;
  font-weight: bolder; */
  font-size: 15px;
  color: #ffffff;
}
.footer-list-icon-ul{
  padding-left: 35px;
}
.footer-list-simple{
  padding-left: 0px;

}
.footer-list-textbox-input{    
  width: 195px;
  font-size: 10px;
  padding: 2px;
}
.footer-list-textbox-input{
  outline-style: none;
  border: none;
}
.footer-list-textbox-body{
  padding-top: 5px;
  width: 250px;
  height: 35px;
  border-radius: 25px;
  background-color: #ffffff;
}
.footer-list-textbox-sendarrow{
  height: 10px;
  width: 15px;
  background-color: #00d887;
  display: inline-block;
  -webkit-clip-path: polygon(0 44%, 64% 45%, 64% 21%, 100% 52%, 64% 83%, 64% 61%, 0 61%);
  clip-path: polygon(0 44%, 64% 45%, 64% 21%, 100% 52%, 64% 83%, 64% 61%, 0 61%);
}

.footer-list-textbox-img1{
  width: 18px;
  margin-left: 7px;
}
.footer-panacloudlogo-cont{
  width: 90px;
}
.footer-list-contactus-icons{
  height: 20px;
  margin-right: 7px;
}

.footer-panacloudlogo-cont b{
  font-size: 12px;
  margin-left: 10px;
  color: white;
}

.footer-list-subscribe-btn{
  font-size: 11px;
  color: #9becd7;
  padding: 8px 30px;
  border-radius: 25px;
  background-color: #1a9779;
  border: 1px solid #9becd7;
}
.footer a{
  color: #ffffffc4;
}
.footer a:hover{
  color: #ffffff7a;
}

.fake-connect{
  display: none;
}
@media(max-width:768px){
  .original-connect{
    display: none;
  }
  .fake-connect{
    display: block;
  }
}