
    
.bg-modal {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.proffesional-list li{
  margin: 10px 0px;
}

.aboutCont{
  background-image: linear-gradient(to bottom, #dfedf0, #e6dada);
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  min-height: 90vh;
}
.whiteCir3{
  width: 136px;
  height: 136px;
  margin: 7px;
  border-radius: 50%;
  position: absolute;
  /* background-color: purple; */
  background-image: url(https://gotest.pk/wp-content/uploads/2015/09/Imran-Khan-Bio.jpg);
  background-size: cover;
  background-position: center;
}

.close {
    float: none;
  font-size: 42px;
  color: white;
  transform: rotate(45deg);
  
}

.cardArea{
    /* box-shadow: 0px 0px 40px -15px lightgrey; */
    width: 250px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .cardArea h1{
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    color: #33322d;
  }
  
  .cardArea p{
    font-family: Montserrat;
      font-size: 12px;
      color: #33322db5;
  }

  .modal-contents::-webkit-scrollbar {
    display: none;
    }
.modalBtn2 {
    width: 120px;
    font-size: 12px;
    height: 40px;
    border-radius: 100px;
    background-image: linear-gradient(to left, #08d88d, #0eb5a3);
    color: white;
    border: none;
    outline-style: none;
  }

  .modalPCont p{
      max-width: 1000px;
      width: 80%;
  }
  .modalPCont {
      width: 100%;
}

.closeBtnBox{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    color: #333;
    cursor: pointer;
    background-color: #08d88d;
}